import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import {
  CutTag,
  DataMatchOutput,
  DataMatchSelection,
  DataMatchStatus,
  EPlusFolder,
  FileStatus,
  FileStructure,
  MetricName,
  MetricNamespace,
  TestFileCheck,
  TextBlock,
  TextcutRectangle,
  UndoEvent,
} from '../../types'
import { every, map } from 'lodash'
import {
  createBindingAndAddOnSelectionHandler,
  enableEvents,
  generateExcelColumns,
  getColumnIndex,
  lettersToNumber,
  loadTextFromRange,
  numberToLetters,
} from '../../workbook'
import { ClockLoader } from 'react-spinners'
import { nanoid } from 'nanoid'
import {
  calculateHeightandWidth,
  findTOpLeftPointFromVertices,
} from '../../utils/spatial'
import { useAppDispatch, useAppSelector } from '../../dispatch'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { addMultipleReferences } from '../../slice/referenceSlice'
import CustomDisclosure from '../CustomDisclosure'
import { pushToUndoStack } from '../../slice/undoSlice'
import ErrorMsg from '../ErrorMsg'
import { getFileStructure, getOfficeErrorTitle } from '../../utils/common'
import { isCustomEvent, isOfficeError, isTextBlock } from '../../utils/guards'
import { GENERAL_ERR_CONTENT, NO_OUTPUT } from '../../constant'
import { useNavigate } from 'react-router-dom'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import {
  CellContext,
  ColumnDef,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined'
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from '@headlessui/react'
import DataMatchOutputDropdown from '../DataMatchOutputDropdown'
import { motion } from 'framer-motion'
import dayjs from 'dayjs'
import { dataMatch } from '../../utils/ocr'
import TextcutRectangleBuilder from '../../utils/TextcutRectangleBuilder'
import useSuccessFiles from '../../hooks/useSuccessFiles'
import { addMetric } from '../../utils/metrics'
import InputRangeElem from './InputRangeElem'
import FilesElem from './FilesElem'
import OutputElem from './OutputElem'
import {
  formatDataMatchResult,
  getDataMatchFolderStruct,
  getExcelOutputRanges,
  getInputFiles,
} from '../../utils/data-match-utils'

const columnHelper = createColumnHelper<DataMatchSelection>()

const dataMatchInputOutputOverlapCheck = async (
  input: string,
  output: string[]
) => {
  const set = new Set(output)
  const noOutputCount = output.reduce((a, b) => {
    if (b === NO_OUTPUT) {
      return a + 1
    }
    return a
  }, 0)
  const setSize = output.includes(NO_OUTPUT)
    ? set.size + noOutputCount - 1
    : set.size + noOutputCount
  if (setSize !== output.length)
    return {
      pass: false,
      message: 'Duplicated output range',
    }
  const arr = await getColumnIndex(input, 0)
  if (arr?.some((val) => output.includes(val))) {
    return {
      pass: false,
      message: 'Overlapped input and output ',
    }
  }
  return {
    pass: true,
    message: '',
  }
}

const createDataMatchTextcutRectangle = (
  range: Excel.Range,
  block: TextBlock & Record<'fileId', unknown>,
  sheetId: string,
  sheetName: string,
  values: any,
  bindingGroupId: string | null = null,
  fileName: string | null = null
) => {
  const nano = nanoid()
  const vs = block.boundingPoly.vertices
  const topLeft = findTOpLeftPointFromVertices(vs)
  const [h, w] = calculateHeightandWidth(vs[0], vs[1], vs[2], vs[3])
  const builder = new TextcutRectangleBuilder()
  const top = topLeft?.y && block.pageH ? (topLeft?.y / block.pageH) * 100 : 0
  builder
    .addBoudingInfo({
      x: topLeft?.x ?? 0,
      y: topLeft?.y ?? 0,
      h,
      w,
    })
    .addSheetInfo({
      range: range.address.split('!')[1],
      sheetId: sheetId,
      sheetName: sheetName,
    })
    .addFileInfo({
      fileId: block.fileId as string,
      filePage: block.filePage!,
    })
    .addOcrInfo({ height: 0, id: '', width: 0 })
    .addStyle({ cellColor: '#C3E9FE', fill: '', stroke: '#A9E0FE' })
    .addBindingId(nano)
    .addDegee(0)
    .addTag(CutTag.DATA_MATCH)
    .addValues([[values]])
    .addTop(top)
  if (bindingGroupId) {
    builder.addBindingGroupId(bindingGroupId)
  }
  if (fileName) {
    builder.addFileName(fileName)
  }
  return builder.build()
}

const DataMatching = () => {
  const [firstRowHeader, setFirstRowHeader] = useState<0 | 1>(0)
  const [inputRange, setInputRange] = useState('')
  const [outputRange, setOutputRange] = useState<string[]>([])
  const [checkAllFiles, setCheckAllFiles] = useState(false)
  const [loading, setLoading] = useState(false)
  const isLocalMode = useAppSelector((state) => state.localMode.isLocalMode)
  const {
    files: data,
    isError,
    isLoading,
    error,
    isFetching,
    refetch,
  } = useSuccessFiles()
  const [primaryColumnOptions, setPrimaryColumnOptions] = useState<string[]>([])
  const [primaryIndiecs, setPrimaryIndices] = useState<number[]>([])
  const navigate = useNavigate()
  const [files, setFiles] = useState<TestFileCheck[]>([])
  const inputRangeRef = useRef<HTMLInputElement>(null)
  const [inputRangeFocus, setInputRangeFocus] = useState(true)
  const [stepper, setStepper] = useState<0 | 1 | 2 | -1>(0)

  const [tableData, setTableData] = useState<DataMatchSelection[]>([])
  const [outputTableError, setOutputTableError] = useState(false)
  const allowCancel = useRef(true)
  const [matchError, setMatchError] = useState(false)
  const [folders, setFolders] = useState<EPlusFolder[]>([])
  const [selectedFolders, setSelectedFolders] = useState<string[]>([])
  const [searchMode, setSearchMode] = useState<0 | 1 | 2>(0)
  const [fileStructure, setFileStructure] = useState<FileStructure>({})

  const onCompulsoryCheckboxChange =
    (info: CellContext<DataMatchSelection, string>) =>
      (e: ChangeEvent<HTMLInputElement>) => {
        const value = info.getValue()
        const index = primaryColumnOptions.findIndex((option) =>
          value.startsWith(option)
        )
        if (index === -1) return
        if (primaryIndiecs.includes(index)) {
          const filtered = primaryIndiecs.filter((val) => val !== index)
          setPrimaryIndices(filtered)
        } else {
          setPrimaryIndices([...primaryIndiecs, index])
        }
      }


  const InputColumn = ({
    info,
  }: {
    info: CellContext<DataMatchSelection, string>
  }) => {
    return (
      <span className="relative flex justify-between">
        {info.getValue()}
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          as="div"
          show={true}
        >
          <Popover className="relative">
            <PopoverButton
              className={
                'hover:bg-gray-200 transition ease-in-out delay-75 hover:scale-110 rounded'
              }
            >
              <MoreHorizOutlinedIcon />
            </PopoverButton>

            <PopoverPanel className="absolute z-10 bg-white border rounded w-40">
              <div className="flex items-center p-2">
                <input
                  checked={primaryIndiecs.some((index) =>
                    info
                      .getValue()
                      .startsWith(primaryColumnOptions.at(index) ?? '@@@')
                  )}
                  type="checkbox"
                  onChange={onCompulsoryCheckboxChange(info)}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label className="ms-2 text-sm font-medium text-gray-900  w-full ">
                  <p>Must find this data</p>
                </label>
              </div>
            </PopoverPanel>
          </Popover>
        </Transition>
      </span>
    )
  }
  // const columns = [
  //   columnHelper.accessor('input', {
  //     cell: (info) => {
  //       return <InputColumn info={info} />
  //     },
  //     header: 'Input Columns',
  //   }),
  //   columnHelper.accessor('output', {
  //     cell: (info) => info.getValue(),
  //     header: 'Output Columns',
  //   }),
  // ]

  const columns = selectedFolders.length
    ? ([
      columnHelper.accessor('input', {
        cell: (info) => {
          return <InputColumn info={info} />
        },
        header: 'Input Columns',
      }),
      ...selectedFolders
        .map((folderId) => {
          const folder = folders.find((folder) => folder.fileId === folderId)
          if (!folder) return null
          return columnHelper.accessor(folderId, {
            cell: (info) => info.getValue(),
            header: folder.folderName,
            id: folderId,
          })
        })
        .filter((val) => val !== null),
    ] as ColumnDef<DataMatchSelection, any>[])
    : [
      columnHelper.accessor('input', {
        cell: (info) => {
          return <InputColumn info={info} />
        },
        header: 'Input Columns',
      }),
      columnHelper.accessor('output', {
        cell: (info) => info.getValue(),
        header: 'Output Columns',
      }),
    ]

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (data) {
      setFolders(data.filter((d) => d.type === 'FOLDER'))
      const arr: TestFileCheck[] = data
        .filter((file) => file.type !== 'FOLDER')
        .filter((file) => file.status === FileStatus.SUCCEEDED)
        .map((file) => ({
          filename: file.fileName ?? '',
          checked: false,
          id: file.fileId,
          ocrPath: file.ocrFileKey,
          path: file.ocrFileKey,
          parentFolderId: file.parentFolderId,
        }))
      setFiles(arr)
      setCheckAllFiles(false)
    }
  }, [data])

  useEffect(() => {
    const func = async () =>
      Excel.run(async (ctx) => {
        const sheet = ctx.workbook.worksheets.getActiveWorksheet()
        if (inputRange === '') return []
        const range = sheet.getRange(inputRange)
        range.load(['columnCount', 'columnIndex', 'isNullObject'])
        await ctx.sync()
        if (range.isNullObject) return []
        const arr: string[] = []
        for (
          let i = range.columnIndex;
          i < range.columnIndex + range.columnCount;
          i++
        ) {
          arr.push(numberToLetters(i))
        }
        return arr
      })

    func()
      .then((res) => {
        setPrimaryColumnOptions(res)
        setPrimaryIndices([])
      })
      .catch((err) => {
        console.error(err)
        setPrimaryColumnOptions([])
      })
  }, [inputRange])

  useEffect(() => {
    const handler = async (event: Event) => {
      if (!isCustomEvent(event)) return
      try {
        const args: Excel.WorksheetSelectionChangedEventArgs = JSON.parse(
          event.detail
        )
        if (inputRangeFocus && inputRangeRef.current) {
          setInputRange(args.address)
          inputRangeRef.current.focus()
        }
      } catch (err) {
        toast.error(`Error: ${err}`)
      }
    }

    window.addEventListener('OnExcelWorkbooksSelectionChange', handler)
    return () =>
      window.removeEventListener('OnExcelWorkbooksSelectionChange', handler)
  }, [inputRangeFocus])

  const getInitialOutputCols = useCallback(
    async (noOutput = false) => {
      const columns = await getColumnIndex(inputRange, firstRowHeader)
      if (!columns) return
      const num = columns.length
        ? lettersToNumber(columns[columns.length - 1][0])
        : -1
      if (selectedFolders.length > 0) {
        const arr: string[] = []
        for (let i = 0; i < selectedFolders.length * columns.length; i++) {
          arr.push(numberToLetters(num + i))
        }
        setOutputRange(arr)
      } else {
        const arr = columns.map((_, i) =>
          noOutput ? NO_OUTPUT : numberToLetters(num + i)
        )
        setOutputRange(arr)
      }
    },
    [firstRowHeader, inputRange, selectedFolders]
  )

  useEffect(() => {
    getInitialOutputCols().catch((err) => console.error(err))
  }, [getInitialOutputCols])

  useEffect(() => {
    const func = () => getColumnIndex(inputRange, firstRowHeader)

    func()
      .then((columns) => {
        if (!columns) return
        const setSelected = (index: number) => (val: string) => {
          const output = [...outputRange]
          output[index] = val
          setOutputRange(output)
        }
        if (selectedFolders.length) {
          const arr: DataMatchSelection[] = columns.map((index, i) => {
            return { input: index }
          })
          let count = 0
          for (let col = 0; col < selectedFolders.length; col++) {
            for (let row = 0; row < columns.length; row++) {
              arr[row][selectedFolders[col]] = (
                <DataMatchOutputDropdown
                  data={generateExcelColumns()}
                  index={count}
                  selected={outputRange.at(count) || NO_OUTPUT}
                  setSelected={setSelected(count)}
                />
              )
              count++
            }
          }
          setTableData(arr)
        } else {
          const arr: DataMatchSelection[] = columns.map((index, i) => {
            return {
              input: index,
              output: (
                <DataMatchOutputDropdown
                  data={generateExcelColumns()}
                  index={i}
                  selected={outputRange.at(i) || NO_OUTPUT}
                  setSelected={setSelected(i)}
                />
              ),
            }
          })
          setTableData(arr)
        }
      })
      .catch((err) => console.error(err))
  }, [inputRange, firstRowHeader, outputRange, selectedFolders])

  useEffect(() => {
    const arr = outputRange.filter((r) => r !== NO_OUTPUT)
    const set = new Set(arr)
    if (set.size !== arr.length) {
      setOutputTableError(true)
    }
  }, [outputRange])

  useEffect(() => {
    if (files.every((f) => f.checked) && !checkAllFiles) {
      setCheckAllFiles(true)
    } else if (files.some((f) => !f.checked) && checkAllFiles) {
      setCheckAllFiles(false)
    }
  }, [files, checkAllFiles])

  useEffect(() => {
    const fileStructure = getFileStructure(folders, files)
    setFileStructure(fileStructure)
  }, [folders, files])

  const onCheckAllFilesChange = () => {
    const arr = files.map((file) => ({
      ...file,
      checked: checkAllFiles ? false : true,
    }))
    setCheckAllFiles(!checkAllFiles)
    setFiles(arr)
  }

  const uncheckAllFiles = () => {
    const arr = files.map((file) => ({ ...file, checked: false }))
    setCheckAllFiles(false)
    setFiles(arr)
  }

  const onSingleFileCheck = (id: string) => () => {
    const newArr = map(files, (f) =>
      f.id === id ? { ...f, checked: !f.checked } : f
    )
    const allChecked = every(newArr, 'checked')
    setCheckAllFiles(allChecked ?? false)
    setFiles(newArr)
  }

  const onInputRangeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value.substring(2, event.target.value.length)
    setInputRange(val)
    if (val)
      selectRange(val).catch((err) => {
        console.error(err)
        console.error('val:', val)
      })
  }

  const dispatchPushToUndoStack = (
    preValues: any[][],
    reference: TextcutRectangle,
    type: 'ADD' | 'DELETE',
    references: TextcutRectangle[] = []
  ) => {
    const event: UndoEvent = {
      id: nanoid(),
      preValues,
      reference,
      type,
      references,
    }
    dispatch(pushToUndoStack(event))
  }

  const getDataMatchReferenceRects = ({
    formattedResult,
    outputRanges,
    worksheet,
    result,
    textResult
  }: {
    outputRanges: Excel.Range[][]
    formattedResult: any[][]
    worksheet: Excel.Worksheet
    result: DataMatchOutput
    textResult: any[][] | undefined
  }) => {
    const rects: TextcutRectangle[] = []
    for (let i = 0; i < formattedResult.length; i++) {
      const findingsOfTheRow = result[i]
      const rangesOfTheRow = outputRanges[i]
      for (let j = 0; j < rangesOfTheRow.length; j++) {
        if (
          formattedResult[i][j] === '' ||
          formattedResult[i][j] === DataMatchStatus.NOT_FOUND
        )
          continue
        else if (formattedResult[i][j] === DataMatchStatus.DUPLICATE) {
          const bindingGroupId = nanoid()
          const findings = findingsOfTheRow.map((f) => f[j])
          const elems: TextcutRectangle[] = []
          for (const finding of findings) {
            if (
              finding == null ||
              !isTextBlock(finding) ||
              !('fileId' in finding) ||
              !('filePage' in finding)
            )
              continue
            const f = files.find((file) => file.id === finding.fileId)
            if (!f) continue
            const range = rangesOfTheRow[j]
            const elem = createDataMatchTextcutRectangle(
              range,
              finding,
              worksheet.id,
              worksheet.name,
              textResult ? textResult[i][j] : formattedResult[i][j],
              bindingGroupId,
              f.filename
            )
            if (elem) {
              rects.push(elem)
              elems.push(elem)
            }
          }

          if (elems.length) {
            dispatchPushToUndoStack(
              rangesOfTheRow[j].values,
              elems[0],
              'ADD',
              elems
            )
          }
        } else {
          const finding = findingsOfTheRow[0][j]
          if (
            finding == null ||
            !isTextBlock(finding) ||
            !('fileId' in finding)
          )
            continue
          const range = rangesOfTheRow[j]
          const elem = createDataMatchTextcutRectangle(
            range,
            finding,
            worksheet.id,
            worksheet.name,
            formattedResult[i][j]
          )
          if (elem) {
            rects.push(elem)
            dispatchPushToUndoStack(range.values, elem, 'ADD')
          }
        }
      }
    }
    return rects
  }

  const setOuputRangeStyle = (range: Excel.Range, value: any) => {
    range.values = [[value]]
    range.format.horizontalAlignment = Excel.HorizontalAlignment.right
    range.format.autofitColumns()
    range.format.font.name = 'Segoe UI'
    range.format.fill.color = '#C3E9FE'
  }

  const createDataMatchBinding = (
    ctx: Excel.RequestContext,
    rects: TextcutRectangle[],
    sheet: Excel.Worksheet
  ) => {
    const bindingSet = new Set<string>()
    for (const elem of rects) {
      if (elem.bindingGroupId && bindingSet.has(elem.bindingGroupId)) continue
      const range = sheet.getRange(elem.rangeAddr)
      createBindingAndAddOnSelectionHandler(ctx, range, 'Range', elem.bindingId)
      if (elem.bindingGroupId) {
        bindingSet.add(elem.bindingGroupId)
      }
    }
  }

  const onMatchDocumentClick = () => {
    const func = async () => {
      setLoading(true)
      allowCancel.current = false
      const inputValues = await loadTextFromRange(inputRange, outputRange)
      const filteredPrimaryIndices = primaryIndiecs.filter(
        (i) => outputRange[i] !== NO_OUTPUT
      )

      const pass = await dataMatchInputOutputOverlapCheck(
        inputRange,
        outputRange
      )
      if (!pass.pass) {
        toast.error(pass.message, {})
        return
      }

      const fetchFilesStart = dayjs()

      const inputFiles = await getInputFiles(files, isLocalMode)

      const fetchFileEnd = dayjs()

      if (inputFiles.length < 1) {
        toast.error('Please upload or select at least one file', {})
        return
      }

      console.log(
        'Fetch files takes:',
        fetchFileEnd.diff(fetchFilesStart, 'millisecond')
      )
      allowCancel.current = true
      const dataMatchStart = dayjs()
      const result = dataMatch(
        inputFiles,
        inputValues.textResult,
        inputValues.valueResult,
        filteredPrimaryIndices,
        firstRowHeader
        // isLocalMode
      )
      const dataMatchEnd = dayjs()
      console.log(
        'data match takes:',
        dataMatchEnd.diff(dataMatchStart, 'millisecond')
      )

      const arr = formatDataMatchResult(result, inputValues)
      const filteredOutputRange = outputRange.filter((r) => r !== NO_OUTPUT)

      allowCancel.current = false
      await Excel.run(async (ctx) => {
        ctx.runtime.load('enableEvents')
        await ctx.sync()
        if (ctx.runtime.enableEvents) {
          ctx.runtime.enableEvents = !ctx.runtime.enableEvents
          await ctx.sync()
        }
        const sheet = ctx.workbook.worksheets.getActiveWorksheet()
        const iRange = sheet.getRange(inputRange)
        iRange.load(['rowCount', 'rowIndex'])
        await ctx.sync()

        const outputRanges = getExcelOutputRanges({
          filteredOutputRange,
          formattedResult: arr,
          inputRange: iRange,
          worksheet: sheet,
        })
        sheet.load(['id', 'name'])

        await ctx.sync()
        if (firstRowHeader) {
          for (let i = 0; i < outputRanges[0].length; i++) {
            if (outputRanges[0][i].values[0][0] !== '') {
              arr[0][i] = outputRanges[0][i].values[0][0]
            }
          }
        }

        const rects = getDataMatchReferenceRects({
          formattedResult: arr,
          outputRanges,
          result,
          worksheet: sheet,
          textResult: inputValues.textResult
        })

        createDataMatchBinding(ctx, rects, sheet)

        await ctx.sync()
        await addMetric({
          namespace: MetricNamespace.DATA_MATCH,
          metric_value: 1,
          metric_name: MetricName.COUNT,
        })
        for (let i = 0; i < arr.length; i++) {
          for (let j = 0; j < arr[i].length; j++) {
            const range = outputRanges[i][j]
            setOuputRangeStyle(range, arr[i][j])
          }
        }
        await ctx.sync()
        await dispatch(addMultipleReferences(rects))
      })
    }
    const start = dayjs()
    func()
      .catch((err) => {
        console.error(err)
        setMatchError(true)
        toast.error(`Error: ${err}`)
      })
      .finally(async () => {
        const end = dayjs()
        console.log('time:', end.diff(start, 'ms'))
        await enableEvents().catch((err) =>
          console.error(`enableEvents error=${err}`)
        )
        allowCancel.current = true
        setLoading(false)
      })
  }

  const onMatchFolderClick = async () => {
    if (!selectedFolders.length) return

    const dataMatchFolderStructs = await getDataMatchFolderStruct({
      data,
      folders,
      inputRange,
      outputRange,
      selectedFolders,
    })

    for (const struct of dataMatchFolderStructs) {
      const pass = await dataMatchInputOutputOverlapCheck(
        inputRange,
        struct.outputColumns
      )

      if (!pass.pass) {
        toast.error(pass.message, {})
        return
      }

      const inputValues = await loadTextFromRange(
        inputRange,
        struct.outputColumns
      )

      const inputFiles = await getInputFiles(
        files.filter((file) => struct.fileIds.includes(file.id)),
        isLocalMode
      )

      if (inputFiles.length < 1) {
        toast.error('Please upload or select at least one file', {})
        return
      }

      const filteredPrimaryIndices = primaryIndiecs.filter(
        (i) => outputRange[i] !== NO_OUTPUT
      )

      const result = dataMatch(
        inputFiles,
        inputValues.textResult,
        inputValues.valueResult,
        filteredPrimaryIndices,
        firstRowHeader
        // isLocalMode
      )

      struct.result = result

      const arr = formatDataMatchResult(result, inputValues)

      struct.formattedResult = arr
      struct.textResult = inputValues.textResult
    }

    await Excel.run(async (ctx) => {
      const sheet = ctx.workbook.worksheets.getActiveWorksheet()
      const iRange = sheet.getRange(inputRange)
      iRange.load(['rowCount', 'rowIndex'])
      sheet.load(['id', 'name'])
      await ctx.sync()

      for (const struct of dataMatchFolderStructs) {
        const filteredOutputRange = struct.outputColumns.filter(
          (r) => r !== NO_OUTPUT
        )

        const outputRanges = getExcelOutputRanges({
          filteredOutputRange,
          formattedResult: struct.formattedResult,
          inputRange: iRange,
          worksheet: sheet,
        })

        await ctx.sync()
        if (firstRowHeader) {
          for (let i = 0; i < outputRanges[0].length; i++) {
            if (outputRanges[0][i].values[0][0] !== '') {
              struct.formattedResult[0][i] = outputRanges[0][i].values[0][0]
            }
          }
        }

        struct.excelRanges = outputRanges

        const rects = getDataMatchReferenceRects({
          formattedResult: struct.formattedResult,
          outputRanges,
          result: struct.result,
          worksheet: sheet,
          textResult: struct.textResult
        })

        struct.rects = rects

        createDataMatchBinding(ctx, struct.rects ?? [], sheet)
      }

      await ctx.sync()

      for (const strcut of dataMatchFolderStructs) {
        for (let i = 0; i < strcut.formattedResult.length; i++) {
          for (let j = 0; j < strcut.formattedResult[i].length; j++) {
            const range = strcut.excelRanges[i][j]
            setOuputRangeStyle(range, strcut.formattedResult[i][j])
          }
        }
      }

      await ctx.sync()

      for (const strcut of dataMatchFolderStructs) {
        await dispatch(addMultipleReferences(strcut.rects ?? []))
      }

      await addMetric({
        namespace: MetricNamespace.DATA_MATCH,
        metric_value: 1,
        metric_name: MetricName.COUNT,
      })
    })
  }

  const onMatchFolderClickWrapper = () => {
    setLoading(true)
    onMatchFolderClick()
      .catch((err) => {
        console.error(`onMatchFolderClick error=${err}`)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const onMatchClick = () => {
    if (searchMode === 1) {
      onMatchDocumentClick()
    } else if (searchMode === 2) {
      onMatchFolderClickWrapper()
    }
  }

  const resetMatchError = async () => {
    try {
      await enableEvents()
      setMatchError(false)
    } catch (error) {
      toast.error(`resetMatchError error=${error}`)
    }
  }

  const selectRange = (range: string) =>
    Excel.run(async (ctx) => {
      const sheet = ctx.workbook.worksheets.getActiveWorksheet()
      const r = sheet.getRange(range)
      r.select()
      await ctx.sync()
    })

  const renderErrorTitle = () => {
    // if (isLocalMode && localError)
    //   return getOfficeErrorTitle(localError.message)
    return isOfficeError(error)
      ? getOfficeErrorTitle(error.message)
      : 'Internal Error'
  }

  const allowStepTwo = () => {
    if (inputRange) {
      setStepper(1)
      setInputRangeFocus(false)
    } else toast.error('Please select input')
  }

  const allowStepThree = () => {
    const count = files.reduce((a, b) => {
      if (b.checked) return a + 1
      return a
    }, 0)
    if (count > 0) {
      setStepper(2)
    } else toast.error('Please select or upload at least one file')
  }

  return (
    <motion.div
      // initial={{ scaleX: 0 }}
      // animate={{ scaleX: 1, transition: { duration: 0.5, ease: 'circOut' } }}
      // exit={{ scaleX: 1, transition: { duration: 0.5, ease: 'circIn' } }}
      // style={{ originX: isPresent ? 0 : 1 }}
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.5,
        delay: 0.3,
        ease: [0, 0.71, 0.2, 1.01],
      }}
      className="h-full"
    >
      <div className="flex flex-col h-full w-full bg-[#f5f5f5] overflow-y-auto p-4 sm:p-8">
        <ToastContainer stacked={true} className="max-w-72" />
        {loading && !isError && !matchError && (
          <div className="flex flex-col justify-center items-center h-full w-full gap-2">
            <div>
              <ClockLoader size={50} color="#36d7b7" />
            </div>
            <div className="font-semibold text-lg">Data match in progress </div>
            <div>
              <button
                className={`bg-slate-200 p-2 text-md font-medium ${allowCancel.current
                  ? 'hover:bg-slate-300 rounded '
                  : 'cursor-not-allowed'
                  }`}
                onClick={() => {
                  setLoading(false)
                  window.location.reload()
                }}
                disabled={!allowCancel.current}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        {!loading && !matchError && (
          <>
            <ol className="relative border-s-2 hidden sm:block">
              <li className="mb-10 ms-4">
                <span className="absolute flex items-center justify-center w-5 h-5 bg-green-50  -start-3 ">
                  {stepper > 0 && (
                    <CheckOutlinedIcon sx={{ fontSize: 18, color: 'gray' }} />
                  )}
                </span>
                <CustomDisclosure
                  header="1. Select input range."
                  element={
                    <InputRangeElem
                      allowStepTwo={allowStepTwo}
                      firstRowHeader={firstRowHeader}
                      inputRange={inputRange}
                      inputRangeRef={inputRangeRef}
                      onInputRangeChange={onInputRangeChange}
                      setFirstRowHeader={setFirstRowHeader}
                    />
                  }
                  open={stepper === 0 ? true : false}
                  defaultOpen={true}
                />
              </li>
              <li className="mb-10 ms-4">
                <span className="absolute flex items-center justify-center w-5 h-5 bg-green-50  -start-3 ">
                  {stepper > 1 && (
                    <CheckOutlinedIcon sx={{ fontSize: 18, color: 'gray' }} />
                  )}
                </span>
                <CustomDisclosure
                  element={
                    <FilesElem
                      allowStepThree={allowStepThree}
                      checkAllFiles={checkAllFiles}
                      fileStructure={fileStructure}
                      files={files}
                      folders={folders}
                      isError={isError}
                      isFetching={isFetching}
                      isLoading={isLoading}
                      navigate={navigate}
                      onCheckAllFilesChange={onCheckAllFilesChange}
                      refetch={refetch}
                      renderErrorTitle={renderErrorTitle}
                      setFiles={setFiles}
                      setInputRangeFocus={setInputRangeFocus}
                      setStepper={setStepper}
                      onSingleFileCheck={onSingleFileCheck}
                      setSelectedFolders={setSelectedFolders}
                      selectedFolders={selectedFolders}
                      searchMode={searchMode}
                      setSearchMode={setSearchMode}
                      uncheckAllFiles={uncheckAllFiles}
                    />
                  }
                  header="2. Select supporting documents or folders if they belong to different types."
                  open={stepper === 1 ? true : false}
                  defaultOpen={true}
                />
              </li>
              <li className="ms-4">
                <span className="absolute flex items-center justify-center w-5 h-5 bg-green-50  -start-3 ">
                  {/* <CheckOutlinedIcon sx={{ fontSize: 18, color: 'gray' }} /> */}
                </span>
                <CustomDisclosure
                  header="3. Select output range."
                  element={
                    <OutputElem
                      getInitialOutputCols={getInitialOutputCols}
                      outputTableError={outputTableError}
                      setOutputTableError={setOutputTableError}
                      setStepper={setStepper}
                      table={table}
                      onMatchClick={onMatchClick}
                    />
                  }
                  open={stepper === 2 ? true : false}
                  defaultOpen={true}
                />
              </li>
            </ol>
            <div className="flex flex-col w-full sm:hidden gap-4">
              <CustomDisclosure
                header="1. Select input range."
                element={
                  <InputRangeElem
                    allowStepTwo={allowStepTwo}
                    firstRowHeader={firstRowHeader}
                    inputRange={inputRange}
                    inputRangeRef={inputRangeRef}
                    onInputRangeChange={onInputRangeChange}
                    setFirstRowHeader={setFirstRowHeader}
                  />
                }
                open={stepper === 0 ? true : false}
                defaultOpen={true}
              />
              <CustomDisclosure
                element={
                  <FilesElem
                    allowStepThree={allowStepThree}
                    checkAllFiles={checkAllFiles}
                    fileStructure={fileStructure}
                    files={files}
                    folders={folders}
                    isError={isError}
                    isFetching={isFetching}
                    isLoading={isLoading}
                    navigate={navigate}
                    onCheckAllFilesChange={onCheckAllFilesChange}
                    refetch={refetch}
                    renderErrorTitle={renderErrorTitle}
                    setFiles={setFiles}
                    setInputRangeFocus={setInputRangeFocus}
                    setStepper={setStepper}
                    onSingleFileCheck={onSingleFileCheck}
                    setSelectedFolders={setSelectedFolders}
                    selectedFolders={selectedFolders}
                    searchMode={searchMode}
                    setSearchMode={setSearchMode}
                    uncheckAllFiles={uncheckAllFiles}
                  />
                }
                header="2. Select supporting documents or folders if they belong to different types."
                open={stepper === 1 ? true : false}
                defaultOpen={true}
              />
              <CustomDisclosure
                header="3. Select output range."
                element={
                  <OutputElem
                    getInitialOutputCols={getInitialOutputCols}
                    outputTableError={outputTableError}
                    setOutputTableError={setOutputTableError}
                    setStepper={setStepper}
                    table={table}
                    onMatchClick={onMatchClick}
                  />
                }
                open={stepper === 2 ? true : false}
                defaultOpen={true}
              />
            </div>
          </>
        )}
        {matchError && (
          <div className="flex w-full">
            <ErrorMsg
              title={'Data Match Error'}
              content={GENERAL_ERR_CONTENT}
              refetch={resetMatchError}
            />
          </div>
        )}
      </div>
    </motion.div>
  )
}

export default DataMatching
