import { INITIALIZE_OFFICE_SETTINGS_ERROR_CONTENT } from "../../constant"
import ErrorMsg from "../ErrorMsg"

type Props = {
  error: Error
  resetErrorBoundary: (...args: any[]) => any
}

const CustomErrorBoundary = ({ error, resetErrorBoundary }: Props) => {
  return <div className="flex flex-col justify-center items-center p-8">
    <ErrorMsg
      title="Error"
      content={INITIALIZE_OFFICE_SETTINGS_ERROR_CONTENT}
      error={error}
      reset={resetErrorBoundary} />
  </div>
}

export default CustomErrorBoundary