import * as React from 'react'
import { SpinnerWrapper, SpinnerWrapperProps } from './Spinner'

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  SpinnerWrapperProps

export const PrimaryButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, children, spinner, spinnerPosition, ...props }, ref) => {
    if (spinner && !spinnerPosition) {
      spinnerPosition = 'overlay'
    }
    return (
      <button
        type="button"
        className={
          `relative inline-flex m-1 justify-center items-center gap-1 rounded-full px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-teal-300 ` +
            (props.disabled || spinner
              ? 'cursor-not-allowed opacity-50 '
              : 'hover:bg-gradient-to-br ') +
            className || ''
        }
        ref={ref}
        {...props}
        disabled={props.disabled || spinner}
      >
        <SpinnerWrapper spinner={spinner} spinnerPosition={spinnerPosition}>
          {children}
        </SpinnerWrapper>
      </button>
    )
  }
)

export const SecondaryButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, children, spinner, spinnerPosition, ...props }, ref) => {
    if (spinner && !spinnerPosition) {
      spinnerPosition = 'overlay'
    }
    return (
      <button
        type="button"
        className={
          `relative inline-flex m-1 text-teal-600 justify-center rounded-full border border-teal-400  px-4 py-2 text-sm font-medium hover:bg-gray-100 ` +
            (props.disabled || spinner
              ? 'cursor-not-allowed opacity-50 '
              : 'hover:bg-gradient-to-br ') +
            className || ''
        }
        ref={ref}
        {...props}
        disabled={props.disabled || spinner}
      >
        <SpinnerWrapper spinner={spinner} spinnerPosition={spinnerPosition}>
          {children}
        </SpinnerWrapper>
      </button>
    )
  }
)

export const SecondaryOutlineButton = React.forwardRef<
  HTMLButtonElement,
  ButtonProps
>(({ className, children, spinner, spinnerPosition, ...props }, ref) => {
  if (spinner && !spinnerPosition) {
    spinnerPosition = 'overlay'
  }
  return (
    <button
      type="button"
      className={
        `relative inline-flex justify-center m-1 text-teal-600  rounded-full border border-teal-400 px-4 py-2 text-sm font-medium hover:bg-gray-100 ` +
          (props.disabled || spinner
            ? 'cursor-not-allowed opacity-50 '
            : 'hover:bg-gradient-to-br ') +
          className || ''
      }
      ref={ref}
      {...props}
      disabled={props.disabled || spinner}
    >
      <SpinnerWrapper spinner={spinner} spinnerPosition={spinnerPosition}>
        {children}
      </SpinnerWrapper>
    </button>
  )
})
