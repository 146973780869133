import { MouseEventHandler, useEffect, useRef, useState } from 'react'

export type CustomContextualMenuProps = {
  x: number
  y: number
  menuItems: { onClick: MouseEventHandler<HTMLLIElement>; elem: JSX.Element }[]
  canvasWidth: number
  canvasHeight: number
}

export const CustomContextualMenu = ({
  x,
  y,
  menuItems,
  canvasHeight,
  canvasWidth
}: CustomContextualMenuProps) => {
  const divRef = useRef<HTMLDivElement>(null)
  const [posX, setPosX] = useState(x)
  const [posY, setPosY] = useState(y)

  useEffect(() => {
    if (!divRef.current) return

    const width = divRef.current.offsetWidth

    const posX = width + x > canvasWidth ? canvasWidth - width : x
    setPosX(posX)
  }, [canvasWidth, x])

  useEffect(() => {
    if (!divRef.current) return
    const height = divRef.current.offsetHeight

    const posY = height + y >= canvasHeight - 30 ? canvasHeight - 30 - height : y
    setPosY(posY)
  }, [canvasHeight, y])

  return (
    <div
      style={{
        top: posY,
        left: posX,
      }}
      className={`absolute  bg-white divide-y divide-gray-100 rounded-lg shadow w-fit h-fit`}
      ref={divRef}
    >
      <ul>
        {menuItems.map((item, i) => (
          <li
            className="px-2 py-2 rounded-lg font-semibold hover:bg-gray-100 w-full text-nowrap"
            onClick={item.onClick}
            key={i}
          >
            {item.elem}
          </li>
        ))}
      </ul>
    </div>
  )
}
