import { JwtPayload } from 'jwt-decode'
import { ReactNode } from 'react'

export type TestFile = {
  id: string
  filename: string
  path: string
  ocrPath: string
  ocrJson?: TestDataOcr
  dateCache?: string[]
}

export type CanvasArea = {
  height: number
  width: number
}

export type Rectangle = {
  x: number
  y: number
  w: number
  h: number
}

export type Coordinates = [number, number, number, number]

export type KonvaRectangle = Rectangle & {
  ocrId: string
  stroke: string | undefined
  referenceId?: string
  row?: number
  col?: number
  startRow?: number
  startCol?: number
  sheetId?: string
  rangeAddr?: string
}

export type Func = (...args: any[]) => any

export enum Cut {
  TEXTCUT = 'TEXTCUT',
  TABLECUT = 'TABLECUT',
  VIEW = 'VIEW',
  LINE = 'LINE',
  WORD = 'WORD',
  SUM = 'SUM',
  REDACT = 'REDACT',
  TABLES = 'TABLES',
  VALIDATE = 'VALIDATION',
  EXCEPTION = 'EXCEPTION',
}

export type TextBlock = {
  id: string
  blockType: BlockType
  description: string
  boundingPoly: { vertices: Vertex[] }
  relationships?: TextBlockRelation[]
  entityTypes?: EntityType[]
  columnIndex?: number
  columnSpan?: number
  rowIndex?: number
  rowSpan?: number
  filePage?: number
  pageH?: number
  pageW?: number
}

type EntityType =
  | 'KEY'
  | 'VALUE'
  | 'COLUMN_HEADER'
  | 'TABLE_TITLE'
  | 'TABLE_FOOTER'
  | 'TABLE_SECTION_TITLE'
  | 'TABLE_SUMMARY'
  | 'STRUCTURED_TABLE'
  | 'SEMI_STRUCTURED_TABLE'

export type TextBlockRelation = {
  type: 'CHILD'
  ids: string[]
}

export enum BlockType {
  PAGE = 'PAGE',
  LINE = 'LINE',
  WORD = 'WORD',
  TABLE = 'TABLE',
  CELL = 'CELL',
}

export type Vertex = {
  x: number
  y: number
}

export type TextAnnotation = TextBlock[]

export type TestDataOcr = {
  textAnnotations: TextAnnotation
  textPreview: string
}[]

export type OCRData = TestDataOcr

export type QuadTreeMiniTextBlock = Omit<
  TextBlock,
  'relationships' | 'boundingPoly'
> & {
  vertices: Vertex[]
}

export type CellBlock = Omit<TextBlock, 'blockType'> & {
  blockType: BlockType.CELL
  columnIndex: number
  columnSpan: number
  rowIndex: number
  rowSpan: number
  text?: string
}

export type Reference = {
  fileId: string
  filePage: number
  type: BlockType
  blockId: string
  table?: boolean
}

export type DataMatchReference = TextBlock & {
  fileId: string
  filePage: number
  row: number
  col: number
  startRow: number
  startCol: number
  sheetId: string
  rangeAddr?: string
}

export type CutRefrence = DataMatchReference

export enum FileStatus {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  PROCESSING = 'PROCESSING',
  UPLOADING = 'UPLOADING',
  MALICIOUS = 'MALICIOUS',
  PENDING = 'PENDING',
  SUCCEEDED = 'SUCCEEDED',
  REDACTING = 'REDACTING',
  INVALID = 'INVALID',
}

export type ExtractionMethod = 'PDF_TEXT' | 'PDF_OCR'
export type ExtractionEngine = 'PDFJS' | 'AWS_TEXTRACT'

export type DynamoFile = {
  createdAt: string
  completedAt: string
  fileId: string
  fileType: string
  type: 'FILE' | 'FOLDER'
  folderName: string
  parentFolderId: string
  ocrFileKey: string
  originalFileKey: string
  status: string
  userId: string
  fileName: string
  originalFileSize: number
  /** @deprecated in favor of `parentFolderId` */
  dir: string
  extractionMethod: ExtractionMethod
  extractionEngine: ExtractionEngine
  isLocal?: boolean
}

export type GetFileType = DynamoFile & {
  ocrUrl: string
  pdfUrl: string
}

export type UserGeneratedLine = {
  line: number[]
  id: string
}

export type ColTextAlignment = 'top' | 'bottom' // 'middle' not supported yet

export type TableOptions = {
  hasHeader: boolean
  groupByColId: string
  colTextAlignment: ColTextAlignment
}

export type TextcutRectangle = Rectangle & {
  fileId: string
  filePage: number
  rangeAddr: string
  sheetId: string
  degree: number
  ocrH: number
  ocrW: number
  tag: CutTag
  bindingId: string
  sheetName: string
  boundingPoly?: { vertices: Vertex[] }
  fill: string
  stroke: string
  values: any[][]
  cellColor: string
  ocrId?: string
  lines?: UserGeneratedLine[]
  bindingGroupId?: string
  fileName?: string
  tableOptions?: TableOptions
  top?: number
}

export enum QUICKCUT_ACCESS_TOKEN {
  'ACCESS_TOKEN' = 'QUICKCUT_ACCESS_TOKEN',
}

export enum FileRotation {
  Rotation = 'ROTATION',
  SET_META = 'SET_META',
}

export type RotationType = {
  curr: number
  meta: number
}

export type SetTextcutValueInput = {
  value: string
  fileId: string
  filePage: number
  drawingRect: Rectangle
  degree: number
  tag: Cut
}

export enum CutTag {
  TEXTCUT = 'TEXTCUT',
  WORD = 'WORD',
  LINE = 'LINE',
  TABLE = 'TABLE',
  DATA_MATCH = 'DATA_MATCH',
  SUM = 'SUM',
  REDACT = 'REDACT',
  VALIDATE = 'VALIDATION',
}

export type CompressedFile = DynamoFile & {
  compressedPDF: string
  compressedOCR: string
}

export type WorkbookPDF = DynamoFile & {
  ocr: TestDataOcr
  pdf: Blob
}

export type FolderStructure = {
  [key: string]: FolderStructure | null
}

export type FolderItem = {
  id: string
  name: string
  parentFolderId?: string
}

export type FileRecord = Pick<DynamoFile, 'fileId' | 'userId' | 'status'> & {
  isLocal?: boolean
}

export type Downloadable = {
  blob: Blob
  fileName: string
  type: string
}

export type UndoEvent = {
  type: 'ADD' | 'DELETE'
  id: string
  reference: TextcutRectangle
  preValues: any[][]
  references?: TextcutRectangle[]
}

// export type Comment = {
//   id: string
//   bindingId: string
//   content: string
//   createdAt: string
//   updatedAt: string
//   createdBy: string
// }

export type SearchAllType = {
  blockId: string
  fileId: string
  fileName: string
  filePage: number
}

export enum AppCustomEvent {
  ON_REFERENCE_SELECTED = 'ON_REFERENCE_SELECTED',
  BINDING_ON_DATA_CHANGE = 'BINDING_ON_DATA_CHANGE',
  SHOW_DUPLICATE_FINDINGS_POP_UP = 'SHOW_DUPLICATE_FINDINGS_POP_UP',
}

export type FileCommentTopic = {
  id: string
  x: number
  y: number
  createdAt: number
  createdBy: string
  comments: FileCommentContent[]
  fileId: string
  filePage: number
  ocrW: number
  ocrH: number
  degree: number
}

export type FileCommentContent = {
  content: string
  createdAt: number
  createdBy: string
}

export type First<T extends any[]> = T[0]
export type Optional<T> = {
  [key in keyof T]?: T[key]
}

export type OfficeRuntimeAccessToken = {
  name: string
  preferred_username: string
  oid: string
}

export type OfficeRuntimeTokenJwtPayload = JwtPayload &
  Optional<OfficeRuntimeAccessToken>

export type DataMatchSelection = {
  input: string
  [key: string]: ReactNode
}

export type DropdownOption = {
  key: string
  value: string
  label: string
}

export enum OfficeSimpleEvent {
  DELETE_ALL_REFERENCES = 'DELETE_ALL_REFERENCES',
  UNDO = 'UNDO',
  REDO = 'REDO',
  ALL_TABLES = 'ALL_TABLES',
}

export enum DataMatchStatus {
  NOT_FOUND = '#Not found',
  DUPLICATE = '#Duplicate',
}

export type FilesForEstimation = {
  fileId: string
  totalPages: number
}

export type BoundingBox = {
  topLeft: Vertex
  topRight: Vertex
  bottomRight: Vertex
  bottomLeft: Vertex
}

export type ShowSelectionOnlyState = {
  showSelectionOnly: boolean
  sheetId: string
  rangeAddress: string
}

export type ExtractlyPlusFile = {
  id: string
  type: 'FILE' | 'FOLDER'
  folderName: string
  parentFolderId: string
  /** @deprecated in favor of `parentFolderId` */
  dir?: string
  pdf: Blob
  ocr: TestDataOcr
  createdAt: Date
  fileName: string
  ocrDateCache: string[]
  extractionMethod: ExtractionMethod
  extractionEngine?: ExtractionEngine
  isLocal?: boolean
}

export type RatioPageArea = {
  page: number
  top: number
  left: number
  width: number
  height: number
  textLines?: TextLine[]
}

export type ParseTableResultCellData = {
  top: number
  left: number
  width: number
  height: number
  text: string
}

export type ParseTableResult = {
  extraction_method: 'stream'
  page: number
  top: number
  left: number
  width: number
  height: number
  right: number
  bottom: number
  data: ParseTableResultCellData[][]
}

export type DataMatchInputElementType = 'DATE' | 'TEXT' | 'NUMBER'

export type DataMatchInputElement = {
  text: string
  type: DataMatchInputElementType
}

export enum OfficeTokenCookie {
  KEY = 'MSToken',
}

export type CreateRecordBatchRequest = {
  files: {
    parentFolderId: string | null
    fileName: string
    dir: string
    contentType: string
  }[]
}

export type CreateRecordBatchResponse = {
  success: boolean
  message?: string
  data: {
    success: boolean
    message?: string
    fileId: string
    uploadURL: string
  }[]
}

export type TextLine = {
  left: number
  top: number
  width: number
  height: number
  text: string
}

export enum PdfZoomInZoomOutEvent {
  ZOOM_IN_ZOMM_OUT_EVENT = 'ZOOM_IN_ZOMM_OUT_EVENT',
}

export type DataMatchRowOutput = any[]
export type DataMatchRowOutputs = DataMatchRowOutput[]
export type DataMatchOutput = DataMatchRowOutputs[]

export type EditTableFn = (
  original: TextcutRectangle,
  lines: UserGeneratedLine[],
  ignoreMatrixCompute?: boolean
) => Promise<string | undefined>

export enum MetricNamespace {
  TEXTCUT = 'ExcelAddin/TextCut',
  TABLECUT = 'ExcelAddin/TableCut',
  REDACT = 'ExcelAddin/Redact',
  SUM = 'ExcelAddin/Sum',
  DATA_MATCH = 'ExcelAddin/DataMatch',
  EDIT_TABLE = 'ExcelAddin/EditTable',
  SEARCH = 'ExcelAddin/Search',
}

export enum MetricName {
  COUNT = 'Count',
}

export type EPlusFolder = {
  parentFolderId?: string
  fileId: string
  folderName: string
  isLocal?: boolean
}

export type FileStructure = {
  [key: string]: FileStructure | null
}

export type TestFileCheck = TestFile & {
  checked: boolean
  parentFolderId: string
}

export type DataMatchFolderStruct = {
  id: string
  name: string
  fileIds: string[]
  outputColumns: string[]
  result: DataMatchOutput
  formattedResult: any[][]
  excelRanges: Excel.Range[][]
  rects?: TextcutRectangle[]
  textResult?: any[][]
}
