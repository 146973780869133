import { Layer, Rect, Stage } from 'react-konva'
import {
  CanvasArea,
  Cut,
  CutTag,
  EditTableFn,
  ExtractionMethod,
  FileCommentContent,
  FileCommentTopic,
  KonvaRectangle,
  MetricName,
  MetricNamespace,
  QuadTreeMiniTextBlock,
  RatioPageArea,
  Rectangle,
  RotationType,
  TextBlock,
  TextcutRectangle,
  UndoEvent,
  UserGeneratedLine,
} from '../../types'
import { Dispatch, useCallback, useEffect, useRef, useState } from 'react'
import { KonvaEventObject } from 'konva/lib/Node'
import {
  breakDownOcr,
  fillterDataBasedOnCutType,
  getTablePage,
  pickUpTableCells,
  snipPossibleContinuousTables,
} from '../../utils/ocr'
import {
  calculateCellsFromTable,
  calculateHeightandWidth,
  convertIntersectedCellToMatrix,
  convertParseTableResult,
  convertToFourPointVertices,
  filterNodes,
  findTOpLeftPointFromVertices,
  getIntersectionPercentage,
  isVertical,
  rectangleVerticesToRectangle,
  rotateAVertex,
  rotateOcrBox,
  smoothOcrTable,
  smoothParseTable,
  sortTextBlockInReadableWay,
  sortTextcutInReadableWay,
} from '../../utils/spatial'
import numeral from 'numeral'
import { Rectangle as QuadtreeRectangle } from '@timohausmann/quadtree-ts'
import { forEach, map } from 'lodash'
import {
  createBindingAndAddOnSelectionHandler,
  formatTable,
  numberToLetters,
} from '../../workbook'
import useQuadTree from '../../hooks/useQuadTree'
import { nanoid } from 'nanoid'
import { useAppDispatch, useAppSelector } from '../../dispatch'
import {
  addReferece,
  deleteReferencesWithBindingId,
  deleteReferencesWithBindingIds,
} from '../../slice/referenceSlice'
// import { useCutEvent } from '../../hooks/useCutEvent'
import { convertPossibleAmountToNumber, numberlize } from '../../utils/number'
import useConfirmRedactListener from '../../hooks/useConfirmRedactListener'
import Popup from '../Popup'
import {
  getFileV2,
  parseTable,
  redactFile,
  startProcessingRedactedDoc,
} from '../../api'
import { useNavigate } from 'react-router-dom'
import { pushToUndoStack } from '../../slice/undoSlice'
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined'
import dayjs from 'dayjs'
import { addComment } from '../../slice/commentSlice'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import { setShowSelectionOnlyRange } from '../../slice/showSelectionOnlySlice'
import useImage from 'use-image'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import DrawLayer from '../DrawLayer'
import { Polygon, polygonInPolygon, polygonIntersectsPolygon } from 'geometric'
import TableCellLayer from '../TableCellLayer'
import TextcutRectangleBuilder from '../../utils/TextcutRectangleBuilder'
import IsSelectingLayer from '../IsSelectingLayer'
import {
  CustomContextualMenu,
  CustomContextualMenuProps,
} from './CustomContextualMenu'
import RenderImage from './RenderImage'
import TextArea from './TextArea'
import { addMetric } from '../../utils/metrics'
import { extractAndParseDate, isValidDate } from '../../utils/date-util'

const REDACTION_CONTENT =
  "Are you sure you want to save all the redactions? This opertion cannot be undone, and you' lose all links for given file"

type Props = {
  width: number
  height: number
  canvasArea: CanvasArea
  pages: Map<string, TextBlock>
  tables: Map<string, TextBlock>
  cells: Map<string, TextBlock>
  lines: Map<string, TextBlock>
  words: Map<string, TextBlock>
  filePage: number
  fileId: string
  extractionMethod: ExtractionMethod | undefined
  rotationDegree: RotationType
  setShowConfirmRedact: Dispatch<React.SetStateAction<boolean>>
  setPageLoading: Dispatch<React.SetStateAction<boolean>>
  searchAllItem: string[]
  setTables: Dispatch<React.SetStateAction<Map<string, TextBlock>>>
  setCells: Dispatch<React.SetStateAction<Map<string, TextBlock>>>
  className?: string
  style?: React.CSSProperties
  allowToast: boolean
  isHoldingCtrl: boolean
}

export type KonvaContainerProps = Props

const getCutTypeStyle = (type: Cut): { stroke: string; fill: string } => {
  if (type === Cut.SUM) {
    return {
      stroke: '#FDE6B6',
      fill: '',
    }
  } else if (type === Cut.TEXTCUT) {
    return {
      stroke: '#B6FBFB',
      fill: '',
    }
  } else if (type === Cut.TABLECUT) {
    return {
      stroke: 'green',
      fill: '',
    }
  } else if (type === Cut.VALIDATE) {
    return {
      stroke: 'rgb(0, 115, 78)',
      fill: 'rgba(204, 235, 224, 0.2)',
    }
  } else if (type === Cut.EXCEPTION) {
    return {
      stroke: 'rgb(156, 0, 6)',
      fill: 'rgba(251, 208, 215, 0.2)',
    }
  } else {
    return {
      stroke: 'rgb(0,0,0)',
      fill: 'rgb(0,0,0)',
    }
  }
}

enum LayerRightClickType {
  REDACT = 'REDACT',
  CUT = 'CUT',
  STAGE = 'STAGE',
}

const getTableRectsAndCellRects = (
  tables: TextBlock[],
  tableReferences: TextcutRectangle[],
  cellMap: Map<string, TextBlock>,
  mapper: (data: TextBlock) => KonvaRectangle,
  cutType: Cut,
  fileId: string,
  filePage: number
): [KonvaRectangle[], KonvaRectangle[]] => {
  const set = new Set<string>()
  const tableRects = map(tables, mapper).filter((rect) => {
    return !tableReferences.some((ref) => {
      if (ref.fileId !== fileId || ref.filePage !== filePage) return false
      const refPoly: Polygon = [
        [ref.x, ref.y],
        [ref.x + ref.w, ref.y],
        [ref.x + ref.w, ref.y + ref.h],
        [ref.x, ref.y + ref.h],
      ]
      const rectPoly: Polygon = [
        [rect.x, rect.y],
        [rect.x + rect.w, rect.y],
        [rect.x + rect.w, rect.y + rect.h],
        [rect.x, rect.y + rect.h],
      ]

      const bool =
        polygonInPolygon(refPoly, rectPoly) ||
        polygonInPolygon(rectPoly, refPoly) ||
        polygonIntersectsPolygon(rectPoly, refPoly)
      if (bool) {
        set.add(rect.ocrId)
      }
      return bool
    })
  })

  const tableCells = pickUpTableCells(
    tables.filter((table) => !set.has(table.id)),
    cellMap,
    cutType
  )
  const cellRects = map(tableCells, mapper)
  return [tableRects, cellRects]
}

const KonvaContainer = ({
  width,
  height,
  pages,
  tables,
  cells,
  lines,
  words,
  filePage,
  fileId,
  extractionMethod,
  rotationDegree,
  setShowConfirmRedact,
  setPageLoading,
  searchAllItem,
  setCells,
  setTables,
  className,
  style,
  isHoldingCtrl
}: Props) => {
  const [drawing, setDrawing] = useState(false)
  const [startPos, setStartPos] = useState<[number, number]>([-1, -1])
  const [endPos, setEndPos] = useState<[number, number]>([-1, -1])
  const [isSelecting, setIsSelecting] = useState(false)
  // useCutEvent(toast)
  const cutType = useAppSelector((state) => state.cutMode.type)
  // const [hoverIdx, setHoverIdx] = useState<number>(-1)
  // const [strokeIdx, setStrokeIdx] = useState<number>(-1)
  const [isMouseMove, setIsMouseMove] = useState(false)
  const [popup, setPopup] = useState(false)
  const navigate = useNavigate()
  const [contextMenuPosition, setContextualMenuPosition] = useState<
    [number, number, number, number]
  >([0, 0, 0, 0])
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [clickedRect, setClickedRect] = useState<string | undefined>(undefined)
  const [layerRightClickType, setLayerRightClickType] = useState<
    LayerRightClickType | undefined
  >(undefined)
  const [popupType, setPopupType] = useState<LayerRightClickType | undefined>(
    undefined
  )
  const textareaRef = useRef<HTMLInputElement>(null)
  const [image] = useImage('/icons/chat-bubble-left.svg')
  const [commentTopicId, setCommentTopicId] = useState<string | undefined>(
    undefined
  )
  const [isFetchingFromBE, setIsFetchingFromBE] = useState(false)

  // const enable = useAppSelector((state) => state.toggle.enable)
  const references = useAppSelector((state) => state.references.references)
  const showSelectionOnly = useAppSelector((state) => state.showSelectionOnly)
  const comments = useAppSelector((state) => state.comments.comments)
  const userEmail = useAppSelector((state) => state.userEmail.email)
  const dispatch = useAppDispatch()

  const textcutRects: TextcutRectangle[] = []
  const referenceRect: TextcutRectangle[] = []
  const dataMatchRects: TextcutRectangle[] = []
  const sumRects: TextcutRectangle[] = []
  const redactRects: TextcutRectangle[] = []

  references.forEach((reference) => {
    if (reference.tag === CutTag.DATA_MATCH) {
      dataMatchRects.push(reference)
    } else if (reference.tag === CutTag.TEXTCUT) {
      textcutRects.push(reference)
    } else if (reference.tag === CutTag.SUM) {
      sumRects.push(reference)
    } else if (reference.tag === CutTag.REDACT) {
      redactRects.push(reference)
    } else {
      referenceRect.push(reference)
    }
  })

  const redacts = redactRects.filter((rect) => rect.fileId === fileId)
  useConfirmRedactListener(setPopup, () =>
    setPopupType(LayerRightClickType.REDACT)
  )

  useEffect(() => {
    if (redacts.length > 0) {
      setShowConfirmRedact(true)
    }

    return () => setShowConfirmRedact(false)
  }, [redacts, setShowConfirmRedact])

  const dispatchPushToUndoStack = useCallback(
    (
      preValues: any[][],
      reference: TextcutRectangle,
      type: 'ADD' | 'DELETE'
    ) => {
      const event: UndoEvent = {
        id: nanoid(),
        preValues,
        reference,
        type,
      }
      dispatch(pushToUndoStack(event))
    },
    [dispatch]
  )

  const confirmRedactPopup = async () => {
    try {
      setPageLoading(true)
      // await dispatch(deleteReferencesWithFileIds([fileId]))
      await dispatch(
        deleteReferencesWithBindingIds(
          redacts.map((redact) => redact.bindingId)
        )
      )
      await redactFile(redacts, fileId)
      const contentType = '' // FIXME: This needs to be set to the correct MIME type.
      await startProcessingRedactedDoc(fileId, contentType) // trigger doc extraction after redaction complete
      await addMetric({
        namespace: MetricNamespace.REDACT,
        metric_value: 1,
        metric_name: MetricName.COUNT,
      })
      navigate('/files')
    } catch (err) {
      console.error(err)
    } finally {
      setPopup(false)
      setPageLoading(false)
      setPopupType(undefined)
    }
  }

  const currentPageOcr = pages.get(String(filePage))
  const [ocrPageH, ocrPageW] = calculateHeightandWidth(
    currentPageOcr?.boundingPoly.vertices[0],
    currentPageOcr?.boundingPoly.vertices[1],
    currentPageOcr?.boundingPoly.vertices[2],
    currentPageOcr?.boundingPoly.vertices[3]
  )

  const filteredData = fillterDataBasedOnCutType(
    currentPageOcr,
    tables,
    cells,
    lines,
    words,
    cutType
  )

  const ocrW =
    rotationDegree.curr === 0 || rotationDegree.curr === 180
      ? ocrPageW
      : ocrPageH
  const ocrH =
    rotationDegree.curr === 0 || rotationDegree.curr === 180
      ? ocrPageH
      : ocrPageW
  const ratioX = numeral(ocrW).divide(width)
  const ratioY = numeral(ocrH).divide(height)

  const quadTree = useQuadTree({
    filteredData,
    height,
    ratioX,
    ratioY,
    width,
  })

  const mapTextBlockToKonvaRect = (data: TextBlock): KonvaRectangle => {
    const vertices = data.boundingPoly.vertices
    const topLeft = findTOpLeftPointFromVertices(vertices)
    const [h, w] = calculateHeightandWidth(
      vertices[0],
      vertices[1],
      vertices[2],
      vertices[3]
    )
    return {
      x: topLeft?.x ?? -1,
      y: topLeft?.y ?? -1,
      w,
      h,
      ocrId: data.id,
      stroke:
        cutType === Cut.WORD || cutType === Cut.LINE ? undefined : 'green',
    }
  }

  // const tableRects = map(
  //   filteredData.filter((data) => data.blockType === 'TABLE'),
  //   mapTextBlockToKonvaRect
  // ).filter(
  //   (rect) =>
  //     !referenceRect.some((ref) => {
  //       const refPoly: Polygon = [
  //         [ref.x, ref.y],
  //         [ref.x + ref.w, ref.y],
  //         [ref.x + ref.w, ref.y + ref.h],
  //         [ref.x, ref.y + ref.h],
  //       ]
  //       const rectPoly: Polygon = [
  //         [rect.x, rect.y],
  //         [rect.x + rect.w, rect.y],
  //         [rect.x + rect.w, rect.y + rect.h],
  //         [rect.x, rect.y + rect.h],
  //       ]
  //       return (
  //         polygonInPolygon(refPoly, rectPoly) ||
  //         polygonInPolygon(rectPoly, refPoly) ||
  //         polygonIntersectsPolygon(rectPoly, refPoly)
  //       )
  //     })
  // )
  // const tableCells = pickUpTableCells(filteredData, cells, cutType)
  // const cellRects = map(tableCells, mapTextBlockToKonvaRect)

  const [tableRects, cellRects] = getTableRectsAndCellRects(
    filteredData.filter((data) => data.blockType === 'TABLE'),
    referenceRect,
    cells,
    mapTextBlockToKonvaRect,
    cutType,
    fileId,
    filePage
  )

  const searchAllItemRects = Array.from(lines.values())
    .filter((line) => searchAllItem.includes(line.id))
    .map(mapTextBlockToKonvaRect)
  const onMouseDown = (e: KonvaEventObject<MouseEvent>) => {
    if (showContextMenu) setShowContextMenu(false)
    if (e.evt.button !== 0) return
    const stage = e.target.getStage()?.getPointerPosition()
    if (
      stage &&
      (cutType === Cut.TEXTCUT ||
        cutType === Cut.SUM ||
        cutType === Cut.REDACT ||
        cutType === Cut.TABLECUT ||
        cutType === Cut.VALIDATE ||
        cutType === Cut.EXCEPTION)
    ) {
      setDrawing(true)
      setStartPos([stage.x, stage.y])
    }
  }

  const getIntersectedCells = (
    area: Polygon,
    nodes: QuadtreeRectangle<QuadTreeMiniTextBlock>[]
  ): TextBlock[] => {
    const filtered = nodes.filter((node) => {
      if (!node.data || node.data.blockType !== 'CELL') return false
      const nodeRect: Polygon = [
        [node.x, node.y],
        [node.x + node.width, node.y],
        [node.x + node.width, node.y + node.height],
        [node.x, node.y + node.height],
      ]
      return (
        polygonInPolygon(nodeRect, area) ||
        polygonInPolygon(area, nodeRect) ||
        polygonIntersectsPolygon(nodeRect, area)
      )
    })
    const intersectedCells: TextBlock[] = []
    for (const rect of filtered) {
      if (rect.data && cells.has(rect.data.id)) {
        const cell = cells.get(rect.data.id)
        if (!cell) continue
        intersectedCells.push(cell)
      }
    }
    return intersectedCells
  }

  const getWordsFromCells = useCallback(
    (cells: TextBlock[], words: Map<string, TextBlock>): TextBlock[] => {
      const wordBlocks: TextBlock[] = []
      cells.forEach((cell) =>
        cell.relationships?.at(0)?.ids.forEach((id) => {
          if (words.has(id)) wordBlocks.push(words.get(id)!)
        })
      )
      return wordBlocks
    },
    []
  )

  const onMouseUp = async () => {
    try {
      if (!drawing) return
      setDrawing(false)
      if (
        Math.abs(startPos[0] - endPos[0]) <= 5 ||
        Math.abs(startPos[1] - endPos[1]) <= 5
      ) {
        setIsSelecting(false)
        setStartPos([-1, -1])
        setEndPos([-1, -1])
        setIsMouseMove(false)
        return
      }

      const rect: Rectangle = {
        x: Math.min(startPos[0], endPos[0]),
        y: Math.min(startPos[1], endPos[1]),
        w: Math.abs(startPos[0] - endPos[0]),
        h: Math.abs(startPos[1] - endPos[1]),
      }

      // canvas viewport rect
      const viewportRect: Rectangle = {
        x: 0,
        y: 0,
        w: width,
        h: height,
      }
      const nodes = quadTree.retrieve(
        new QuadtreeRectangle({
          x: rect.x,
          y: rect.y,
          width: rect.w,
          height: rect.h,
        })
      )
      const filteredNodes = filterNodes(
        new QuadtreeRectangle({
          x: rect.x,
          y: rect.y,
          width: rect.w,
          height: rect.h,
        }),
        nodes
      )

      const wordNodes = filteredNodes.filter(
        (node) => node.data && node.data.blockType === 'WORD'
      )
      wordNodes.forEach((node) => {
        if (
          node.data?.description === '\x00' ||
          node.data?.description === '\u0000'
        )
          node.data.description = '-'
      })
      if (isMouseMove && cutType === Cut.TABLECUT) {
        const area: Polygon = [
          [rect.x, rect.y],
          [rect.x + rect.w, rect.y],
          [rect.x + rect.w, rect.y + rect.h],
          [rect.x, rect.y + rect.h],
        ]
        let matrix: any[][] = []
        let lines: UserGeneratedLine[] = []
        let x1: number,
          y1: number,
          x3: number,
          y3: number,
          height: number,
          width: number
        const xs: number[] = []
        const ys: number[] = []
        const intersectedCells = getIntersectedCells(area, nodes)
        if (!intersectedCells.length) {
          const ratioPageArea: RatioPageArea = {
            page: filePage,
            top: rect.y / viewportRect.h,
            left: rect.x / viewportRect.w,
            width: rect.w / viewportRect.w,
            height: rect.h / viewportRect.h,
          }
          setIsFetchingFromBE(true)

          if (extractionMethod === 'PDF_OCR') {
            ratioPageArea['textLines'] = wordNodes.map((node) => ({
              text: node.data!.description,
              left: node.x / viewportRect.w,
              top: node.y / viewportRect.h,
              width: node.width / viewportRect.w,
              height: node.height / viewportRect.h,
            }))
          }
          const { data: tables } = await parseTable({
            fileId,
            ratioPageArea,
          }).catch((err) => {
            console.error(err)
            return { data: null }
          })
          setIsFetchingFromBE(false)
          if (!tables || !tables.length) {
            throw new Error('Unable to extract the table from selection')
          }
          const table = tables[0]
          if (table.data.length > 0) {
            const parsedTable = convertParseTableResult(
              table,
              viewportRect.w * ratioX.value()!,
              viewportRect.h * ratioY.value()!
            )
            matrix = parsedTable.data.map((row) => row.map((item) => item.text))
            x1 = parsedTable.left
            y1 = parsedTable.top
            x3 = parsedTable.right
            y3 = parsedTable.bottom
            width = parsedTable.width
            height = parsedTable.height
            const smoothResult = smoothParseTable(parsedTable)
            xs.push(...smoothResult[0])
            ys.push(...smoothResult[1])
            for (let i = 1; i < xs.length - 1; i++) {
              const line = [xs[i], y1, xs[i], y1 + height]
              lines.push({ line, id: nanoid() })
            }
            for (let i = 1; i < ys.length - 1; i++) {
              const line = [x1, ys[i], x1 + width, ys[i]]
              lines.push({ line, id: nanoid() })
            }
          } else {
            const sortedNodes = sortTextcutInReadableWay(wordNodes)
            const str = sortedNodes
              .map((node) => node.data!.description)
              .join(' ')
            matrix = [[str]]
            x1 = rect.x * ratioX.value()!
            y1 = rect.y * ratioY.value()!
            x3 = (rect.x + rect.w) * ratioX.value()!
            y3 = (rect.y + rect.h) * ratioY.value()!
            xs.push(...[x1, x3])
            ys.push(...[y1, y3])
            width = x3 - x1
            height = y3 - y1
          }
        } else {
          const rows = intersectedCells.map((cell) => cell.rowIndex)
          const cols = intersectedCells.map((cell) => cell.columnIndex)
          if (rows.includes(undefined) || cols.includes(undefined)) return
          const minRowIndex = Math.min(...(rows as number[]))
          const maxRowIndex = Math.max(...(rows as number[]))
          const ROW = maxRowIndex - minRowIndex + 1
          const minColIndex = Math.min(...(cols as number[]))
          const maxColIndex = Math.max(...(cols as number[]))
          const COL = maxColIndex - minColIndex + 1
          const cells2d = convertIntersectedCellToMatrix(
            intersectedCells,
            ROW,
            COL,
            minRowIndex,
            minColIndex
          )

          if (!cells2d.length) throw new Error('Invalid Cells')

          const [xs, ys] = smoothOcrTable(cells2d, ROW, COL)
          x1 = xs[0]
          y1 = ys[0]
          x3 = xs[xs.length - 1]
          y3 = ys[ys.length - 1]
          width = x3 - x1
          height = y3 - y1
          if (x1 === x3 || y1 === y3) throw new Error('Invalid Cells')

          for (let i = 1; i < xs.length - 1; i++) {
            const line = [xs[i], y1, xs[i], y1 + height]
            lines.push({ line, id: nanoid() })
          }
          for (let i = 1; i < ys.length - 1; i++) {
            const line = [x1, ys[i], x1 + width, ys[i]]
            lines.push({ line, id: nanoid() })
          }
          const allWordsInCells = getWordsFromCells(intersectedCells, words)
          for (let i = 0; i < ys.length - 1; i++) {
            const arr: any[] = []
            for (let j = 0; j < xs.length - 1; j++) {
              const poly: Polygon = [
                [xs[j] - 1, ys[i] - 1],
                [xs[j + 1] + 1, ys[i] - 1],
                [xs[j + 1] + 1, ys[i + 1] + 1],
                [xs[j] - 1, ys[i + 1] + 1],
              ]
              const wordsInCell = allWordsInCells.filter((word) => {
                const wordPoly: Polygon = word.boundingPoly.vertices.map(
                  (vertex) => [vertex.x, vertex.y]
                )
                return polygonInPolygon(wordPoly, poly)
              })
              const str = sortTextBlockInReadableWay(wordsInCell)
                .map((word) => word.description)
                .join(' ')
              arr[j] = str
            }
            matrix.push(arr)
          }
        }

        await Excel.run(async (ctx) => {
          // matrix = []
          // table.data.forEach((row) => {
          //   const texts: string[] = []
          //   row.forEach((item) => {
          //     texts.push(item.text)
          //   })
          //   matrix.push(texts)
          // })

          const range = ctx.workbook.getSelectedRange()
          const sheet = ctx.workbook.worksheets.getActiveWorksheet()
          range.load('columnIndex')
          range.load('rowIndex')
          sheet.load(['id', 'name'])
          await ctx.sync()
          // const [startRow, startCol] = [range.rowIndex, range.columnIndex]
          // const [endRow, endCol] = [
          //   startRow + ys.length - 1 - 1,
          //   startCol + xs.length - 1 - 1,
          // ]

          // calculate the range, take range as starting point
          const tableRowCount = matrix.length
          const tableColCount = matrix[0].length

          const startCol = range.columnIndex
          const startRow = range.rowIndex
          const endCol = startCol + tableColCount - 1
          const endRow = startRow + tableRowCount - 1

          const matrixRange = `${numberToLetters(startCol)}${startRow + 1
            }:${numberToLetters(endCol)}${endRow + 1}`
          const outputRange = ctx.workbook.worksheets
            .getActiveWorksheet()
            .getRange(matrixRange)
          outputRange.load([
            'rowIndex',
            'columnIndex',
            'rowCount',
            'columnCount',
            'address',
            'values',
          ])

          await ctx.sync()
          const preValues = outputRange.values
          const parsedMatrix = matrix.map((row) =>
            row.map((item) => {
              const date = extractAndParseDate(item)
              const validDate = isValidDate(item)
              return validDate.valid && date ? date : item
            })
          )
          const transposedMatrix = parsedMatrix
          formatTable(outputRange, transposedMatrix as string[][])
          const nano = nanoid()
          const vs = [
            { x: x1, y: y1 },
            { x: x1 + width, y: y1 },
            { x: x1 + width, y: y1 + height },
            { x: x1, y: y1 + height },
          ]
          const topLeft = findTOpLeftPointFromVertices(vs)
          const [h, w] = calculateHeightandWidth(vs[0], vs[1], vs[2], vs[3])
          const [, rangeAddr] = outputRange.address.split('!')
          const top =
            topLeft?.y && ratioY.value()
              ? ((topLeft.y * ratioY.value()!) / ocrH) * 100
              : 0
          const elem = new TextcutRectangleBuilder()
            .addBoudingInfo({
              x: topLeft?.x ?? 0,
              y: topLeft?.y ?? 0,
              h,
              w,
            })
            .addSheetInfo({
              range: rangeAddr,
              sheetId: sheet.id,
              sheetName: sheet.name,
            })
            .addFileInfo({ fileId, filePage })
            .addOcrInfo({ height: ocrH, id: '', width: ocrW })
            .addStyle({ cellColor: '#90EE90', fill: '', stroke: 'green' })
            .addBindingId(nano)
            .addDegee(rotationDegree.curr)
            .addTag(CutTag.TABLE)
            .addValues(parsedMatrix)
            .addLines(lines)
            .addTop(top)
            .build()
          const nextRange = sheet.getRangeByIndexes(
            outputRange.rowIndex + outputRange.rowCount,
            outputRange.columnIndex,
            1,
            1
          )
          nextRange.select()
          if (elem) {
            createBindingAndAddOnSelectionHandler(
              ctx,
              outputRange,
              'Range',
              nano
            )
            dispatchGeneralAddRefActions(elem!, preValues)
              .then(() =>
                addMetric({
                  namespace: MetricNamespace.TABLECUT,
                  metric_value: 1,
                  metric_name: MetricName.COUNT,
                })
              )
              .catch((err) => {
                console.error('Error:', err)
                toast.error((err as unknown as Error).message)
              })
              .finally(() => {
                setIsSelecting(false)
                setStartPos([-1, -1])
                setEndPos([-1, -1])
                setIsMouseMove(false)
              })
          }
        })
      } else if (isMouseMove && cutType === Cut.TEXTCUT) {
        const sortedNodes = sortTextcutInReadableWay(wordNodes)
        let text = ''
        forEach(sortedNodes, (node) => (text += node?.data?.description + ' '))
        if (text) {
          await Excel.run(async (ctx) => {
            const textDate = extractAndParseDate(text)
            const validDate = isValidDate(text)
            const sheet = ctx.workbook.worksheets.getActiveWorksheet()
            const range = ctx.workbook.getSelectedRange()
            sheet.load(['id', 'name'])
            range.load(['rowIndex', 'columnIndex'])
            await ctx.sync()
            const firstCell = sheet.getCell(range.rowIndex, range.columnIndex)
            const nano = nanoid()
            range.load(['rowIndex', 'columnIndex', 'rowCount'])
            sheet.load('id')
            firstCell.load(['address', 'values'])
            await ctx.sync()
            const [, rangeAddr] = firstCell.address.split('!')
            const top =
              rect?.y && ratioY.value()
                ? ((rect.y * ratioY.value()!) / ocrH) * 100
                : 0
            const elem = new TextcutRectangleBuilder()
              .addBindingId(nano)
              .addBoudingInfo({
                x: rect.x * (ratioX.value() ?? 1),
                y: rect.y * (ratioY.value() ?? 1),
                h: rect.h * (ratioY.value() ?? 1),
                w: rect.w * (ratioX.value() ?? 1),
              })
              .addFileInfo({ fileId, filePage })
              .addSheetInfo({
                sheetId: sheet.id,
                sheetName: sheet.name,
                range: rangeAddr,
              })
              .addOcrInfo({
                id: '',
                height:
                  rotationDegree.curr === 0 || rotationDegree.curr === 180
                    ? ocrPageH
                    : ocrPageW,
                width:
                  rotationDegree.curr === 0 || rotationDegree.curr === 180
                    ? ocrPageW
                    : ocrPageH,
              })
              .addTag(CutTag.TEXTCUT)
              .addStyle({ cellColor: '#CCFCFC', ...getCutTypeStyle(cutType) })
              .addValues([[validDate.valid && textDate ? textDate : text]])
              .addDegee(rotationDegree.curr)
              .addTop(top)
              .build()
            const nextRange = sheet.getRangeByIndexes(
              range.rowIndex + range.rowCount,
              range.columnIndex,
              1,
              1
            )
            nextRange.select()
            if (elem) {
              const preValues = firstCell.values
              // firstCell.numberFormat = [['@']]
              firstCell.values = [
                [validDate.valid && textDate ? textDate : text],
              ]
              firstCell.format.font.name = 'Segoe UI'
              firstCell.format.fill.color = '#CCFCFC'
              firstCell.format.autofitColumns()
              createBindingAndAddOnSelectionHandler(
                ctx,
                firstCell,
                'Range',
                nano
              )
              await ctx.sync()
              dispatchGeneralAddRefActions(elem, preValues)
                .then(() =>
                  addMetric({
                    namespace: MetricNamespace.TEXTCUT,
                    metric_value: 1,
                    metric_name: MetricName.COUNT,
                  })
                )
                .catch((err) => {
                  console.error('Error:', err)
                  toast.error((err as unknown as Error).message)
                })
            }
          })
        }
      } else if (isMouseMove && cutType === Cut.SUM) {
        const sortedNodes = sortTextcutInReadableWay(wordNodes)
        let text = ''
        const tmp = sortedNodes.map((node) =>
          numberlize(
            node.data?.description ?? '',
            convertPossibleAmountToNumber
          )
        )
        const tmp2 = tmp.filter((val) => !isNaN(val))
        text = tmp2.length > 0 ? `=${tmp2.join('+')}` : 'NaN'
        if (text !== 'NaN') {
          const groupId = nanoid()
          await Excel.run(async (ctx) => {
            const sheet = ctx.workbook.worksheets.getActiveWorksheet()
            const range = ctx.workbook.getSelectedRange()
            sheet.load(['id', 'name'])
            range.load(['rowIndex', 'columnIndex', 'rowCount'])
            await ctx.sync()
            const firstCell = sheet.getCell(range.rowIndex, range.columnIndex)
            const nano = nanoid()
            range.load(['rowIndex', 'columnIndex'])
            sheet.load('id')
            firstCell.load(['address', 'values', 'formulas'])
            await ctx.sync()
            const [, rangeAddr] = firstCell.address.split('!')
            let preSumElem: TextcutRectangle | undefined
            if (isHoldingCtrl) {
              preSumElem = sumRects.find(
                (rect) =>
                  rect.tag === CutTag.SUM &&
                  rect.sheetId === sheet.id &&
                  rect.filePage === filePage &&
                  rect.rangeAddr === rangeAddr
              )
              if (preSumElem) {
                text = firstCell.formulas + '+' + text.slice(1)
                if (!text.startsWith('=')) text = '=' + text
              }
            }

            const elem = new TextcutRectangleBuilder()
              .addBindingId(nano)
              .addBoudingInfo({
                x: rect.x * (ratioX.value() ?? 1),
                y: rect.y * (ratioY.value() ?? 1),
                h: rect.h * (ratioY.value() ?? 1),
                w: rect.w * (ratioX.value() ?? 1),
              })
              .addFileInfo({ fileId, filePage })
              .addSheetInfo({
                sheetId: sheet.id,
                sheetName: sheet.name,
                range: rangeAddr,
              })
              .addOcrInfo({
                id: '',
                height:
                  rotationDegree.curr === 0 || rotationDegree.curr === 180
                    ? ocrPageH
                    : ocrPageW,
                width:
                  rotationDegree.curr === 0 || rotationDegree.curr === 180
                    ? ocrPageW
                    : ocrPageH,
              })
              .addTag(CutTag.SUM)
              .addStyle({ cellColor: '#FEEECC', ...getCutTypeStyle(cutType) })
              .addValues([[text]])
              .addDegee(rotationDegree.curr)
              .addBindingGroupId(
                preSumElem ? preSumElem.bindingGroupId! : groupId
              )
              .build()
            const nextRange = sheet.getRangeByIndexes(
              range.rowIndex + range.rowCount,
              range.columnIndex,
              1,
              1
            )
            if (elem) {
              if (!preSumElem) {
                const filtered = sumRects.filter(
                  (rect) =>
                    rect.sheetId === sheet.id && rect.rangeAddr === rangeAddr
                )
                if (filtered.length) {
                  await dispatch(
                    deleteReferencesWithBindingIds(
                      filtered.map((f) => f.bindingId)
                    )
                  )
                }
              }
              if (isHoldingCtrl) {
                firstCell.select()
              } else {
                nextRange.select()
              }
              console.log('text:', text)
              firstCell.values = [[text]]
              const preValues = firstCell.values
              firstCell.format.font.name = 'Segoe UI'
              firstCell.format.fill.color = '#FEEECC'
              firstCell.format.autofitColumns()
              createBindingAndAddOnSelectionHandler(
                ctx,
                firstCell,
                'Range',
                nano
              )
              await ctx.sync()
              dispatchGeneralAddRefActions(elem, preValues)
                .then(() =>
                  addMetric({
                    namespace: MetricNamespace.SUM,
                    metric_value: 1,
                    metric_name: MetricName.COUNT,
                  })
                )
                .catch((err) => {
                  console.error('Error:', err)
                  toast.error((err as unknown as Error).message)
                })
            }
          })
        }
      } else if (isMouseMove && cutType === Cut.REDACT) {
        const nano = nanoid()
        const elem = new TextcutRectangleBuilder()
          .addBindingId(nano)
          .addBoudingInfo({
            x: rect.x * (ratioX.value() ?? 1),
            y: rect.y * (ratioY.value() ?? 1),
            h: rect.h * (ratioY.value() ?? 1),
            w: rect.w * (ratioX.value() ?? 1),
          })
          .addFileInfo({ fileId, filePage })
          .addSheetInfo({
            sheetId: '',
            sheetName: '',
            range: '',
          })
          .addOcrInfo({
            id: '',
            height:
              rotationDegree.curr === 0 || rotationDegree.curr === 180
                ? ocrPageH
                : ocrPageW,
            width:
              rotationDegree.curr === 0 || rotationDegree.curr === 180
                ? ocrPageW
                : ocrPageH,
          })
          .addTag(CutTag.REDACT)
          .addStyle({ cellColor: '', ...getCutTypeStyle(cutType) })
          .addValues([])
          .addDegee(rotationDegree.curr)
          .build()
        if (elem) {
          dispatch(addReferece(elem))
            .then(() => dispatchPushToUndoStack([['']], elem, 'ADD'))
            .catch((err) => {
              console.error('Error:', err)
              toast.error((err as unknown as Error).message)
            })
        }
      } else if (
        isMouseMove &&
        (cutType === Cut.VALIDATE || cutType === Cut.EXCEPTION)
      ) {
        await Excel.run(async (ctx) => {
          const sheet = ctx.workbook.worksheets.getActiveWorksheet()
          const range = ctx.workbook.getSelectedRange()
          sheet.load(['id', 'name'])
          range.load(['rowIndex', 'columnIndex'])
          await ctx.sync()
          const firstCell = sheet.getCell(range.rowIndex, range.columnIndex)
          const nano = nanoid()
          range.load(['rowIndex', 'columnIndex', 'rowCount'])
          sheet.load('id')
          firstCell.load(['address', 'values'])
          await ctx.sync()
          const [, rangeAddr] = firstCell.address.split('!')
          const top =
            rect?.y && ratioY.value()
              ? ((rect.y * ratioY.value()!) / ocrH) * 100
              : 0
          const elem = new TextcutRectangleBuilder()
            .addBindingId(nano)
            .addBoudingInfo({
              x: rect.x * (ratioX.value() ?? 1),
              y: rect.y * (ratioY.value() ?? 1),
              h: rect.h * (ratioY.value() ?? 1),
              w: rect.w * (ratioX.value() ?? 1),
            })
            .addFileInfo({ fileId, filePage })
            .addSheetInfo({
              sheetId: sheet.id,
              sheetName: sheet.name,
              range: rangeAddr,
            })
            .addOcrInfo({
              id: '',
              height:
                rotationDegree.curr === 0 || rotationDegree.curr === 180
                  ? ocrPageH
                  : ocrPageW,
              width:
                rotationDegree.curr === 0 || rotationDegree.curr === 180
                  ? ocrPageW
                  : ocrPageH,
            })
            .addTag(CutTag.VALIDATE)
            .addStyle({ cellColor: '#CCFCFC', ...getCutTypeStyle(cutType) })
            .addValues([[`${cutType === Cut.VALIDATE ? '\u2714' : '\u00D7'}`]])
            .addDegee(rotationDegree.curr)
            .addTop(top)
            .build()
          const nextRange = sheet.getRangeByIndexes(
            range.rowIndex + range.rowCount,
            range.columnIndex,
            1,
            1
          )
          nextRange.select()
          if (elem) {
            const preValues = firstCell.values
            // firstCell.numberFormat = [['@']]
            firstCell.values = [
              [`${cutType === Cut.VALIDATE ? '\u2714' : '\u00D7'}`],
            ]
            firstCell.format.font.name = 'Segoe UI'
            firstCell.format.fill.color =
              cutType === Cut.VALIDATE ? '#CCEBE0' : '#FBD0D7'
            firstCell.format.autofitColumns()
            firstCell.format.font.color =
              cutType === Cut.VALIDATE ? '#00734E' : '#9C0006'
            firstCell.format.verticalAlignment = 'Center'
            firstCell.format.horizontalAlignment = 'Center'
            createBindingAndAddOnSelectionHandler(ctx, firstCell, 'Range', nano)
            await ctx.sync()
            dispatchGeneralAddRefActions(elem, preValues)
              .then(() =>
                addMetric({
                  namespace: MetricNamespace.TEXTCUT,
                  metric_value: 1,
                  metric_name: MetricName.COUNT,
                })
              )
              .catch((err) => {
                console.error('Error:', err)
                toast.error((err as unknown as Error).message)
              })
          }
        })
      }
    } catch (error) {
      console.error('Error:', error)
      toast.error((error as unknown as Error).message)
    } finally {
      setIsSelecting(false)
      setStartPos([-1, -1])
      setEndPos([-1, -1])
      setIsMouseMove(false)
    }
  }

  const editTable: EditTableFn = async (
    original: TextcutRectangle,
    lines: UserGeneratedLine[],
    ignoreMatrixCompute = false
  ) => {
    if (original.tag !== CutTag.TABLE) return
    const { x, y, w, h } = original
    const verticalLines = lines.filter((line) => isVertical(line.line))
    const horizontalLines = lines.filter((line) => !isVertical(line.line))
    const vLineSet = new Set<number>([x, x + w])
    const hLineSet = new Set<number>([y, y + h])
    for (const line of verticalLines) {
      const [x1, , x2] = line.line
      vLineSet.add(Math.round(x1))
      vLineSet.add(Math.round(x2))
    }
    for (const line of horizontalLines) {
      const [, y1, , y2] = line.line
      hLineSet.add(Math.round(y1))
      hLineSet.add(Math.round(y2))
    }
    const vLines = [...vLineSet].sort((a, b) => a - b)
    const hLines = [...hLineSet].sort((a, b) => a - b)
    const cellGrid = calculateCellsFromTable(vLines, hLines)
    let matrix: any[][] = []
    if (ignoreMatrixCompute) {
      matrix = original.values
    } else {
      for (let i = 0; i < cellGrid.length; i++) {
        const row: any[] = []
        for (let j = 0; j < cellGrid[i].length; j++) {
          const [[x1, y1], [x2], [, y3], [,]] = cellGrid[i][j]
          const x = numeral(x1).divide(ratioX.value()).value()
          const y = numeral(y1).divide(ratioY.value()).value()
          const width = numeral(x2 - x1)
            .divide(ratioX.value())
            .value()
          const height = numeral(y3 - y1)
            .divide(ratioY.value())
            .value()
          if (x == null || y === null || width === null || height === null)
            continue
          const nodes = quadTree.retrieve(
            new QuadtreeRectangle({
              x: x,
              y: y,
              width: width,
              height: height,
            })
          )
          const posibleWords = nodes.map((node) => {
            if (node.data && node.data.blockType === 'WORD') {
              return words.get(node.data.id) ?? null
            }
            return null
          })
          const filteredPossibleWords = posibleWords.filter(
            (word) => word !== null
          )
          const wordBlockArray: TextBlock[] = []
          for (const word of filteredPossibleWords) {
            if (!word) continue
            const polygon = rectangleVerticesToRectangle(
              word.boundingPoly.vertices
            )
            if (!polygon) continue
            if (
              (polygonIntersectsPolygon(polygon, cellGrid[i][j]) &&
                getIntersectionPercentage(polygon, cellGrid[i][j]) >= 0.8) ||
              polygonInPolygon(polygon, cellGrid[i][j])
            )
              wordBlockArray.push(word)
          }
          const str = sortTextBlockInReadableWay(wordBlockArray)
            .map((block) => block.description)
            .join(' ')
          row.push(str)
        }
        matrix.push(row)
      }
    }
    return Excel.run(async (ctx) => {
      await dispatch(deleteReferencesWithBindingId(original.bindingId))
      const sheet = ctx.workbook.worksheets.getItem(original.sheetId)
      sheet.activate()
      const range = sheet.getRange(original.rangeAddr)
      range.select()
      range.clear()
      range.load(['columnIndex', 'rowIndex'])
      await ctx.sync()
      const [startRow, startCol] = [range.rowIndex, range.columnIndex]
      const [endRow, endCol] = [
        startRow + matrix.length - 1,
        startCol + matrix[0].length - 1,
      ]
      const matrixRange = `${numberToLetters(startCol)}${startRow + 1
        }:${numberToLetters(endCol)}${endRow + 1}`
      const outputRange = ctx.workbook.worksheets
        .getActiveWorksheet()
        .getRange(matrixRange)
      outputRange.load([
        'rowIndex',
        'columnIndex',
        'rowCount',
        'columnCount',
        'address',
        'values',
      ])

      await ctx.sync()
      const preValues = outputRange.values
      const parsedMatrix = matrix.map((row) =>
        row.map((item) => {
          const date = extractAndParseDate(item)
          const validDate = isValidDate(item)
          return validDate.valid && date ? date : item
        })
      )
      const transposedMatrix = parsedMatrix
      formatTable(outputRange, transposedMatrix as string[][])
      const nano = nanoid()
      const [, rangeAddr] = outputRange.address.split('!')

      const elem = new TextcutRectangleBuilder()
        .addBoudingInfo({
          x: original.x,
          y: original.y,
          h: original.h,
          w: original.w,
        })
        .addSheetInfo({
          range: rangeAddr,
          sheetId: sheet.id,
          sheetName: original.sheetName,
        })
        .addFileInfo({ fileId, filePage })
        .addOcrInfo({ height: ocrH, id: '', width: ocrW })
        .addStyle({ cellColor: '#CCFCFC', fill: '', stroke: 'green' })
        .addBindingId(nano)
        .addDegee(rotationDegree.curr)
        .addTag(CutTag.TABLE)
        .addValues(parsedMatrix)
        .addLines(original.lines ?? [])
        .addTableOption(original.tableOptions)
        .build()
      if (elem) {
        createBindingAndAddOnSelectionHandler(ctx, outputRange, 'Range', nano)
        await dispatchGeneralAddRefActions(elem, preValues)
        await addMetric({
          namespace: MetricNamespace.EDIT_TABLE,
          metric_value: 1,
          metric_name: MetricName.COUNT,
        })
        return nano
      }
    })
  }

  const onMouseMove = (e: KonvaEventObject<MouseEvent>) => {
    if (!drawing) {
      return
    }
    if (!isSelecting) setIsSelecting(true)
    const stage = e.target.getStage()?.getPointerPosition()

    if (!stage) {
      setIsSelecting(false)
      setDrawing(false)
      setStartPos([-1, -1])
      return
    }
    setIsMouseMove(true)
    setEndPos([stage.x, stage.y])
  }

  const onTableClick = async (tableId: string, type: Cut) => {
    try {
      if (type === Cut.TABLES) {
        const table = tables.get(tableId)
        if (!table) return
        const { ocr } = await getFileV2(fileId)
        const [pages, allTables, cells, , words] = breakDownOcr(ocr, 0)
        const possibleTables = snipPossibleContinuousTables({
          table,
          filePage,
          tables: allTables,
          cells,
          pages,
          words,
        })
        for (const table of possibleTables) {
          await snipTable(allTables, cells, words, table.id, table.filePage)
        }
      } else if (type === Cut.TABLECUT) {
        await snipTable(tables, cells, words, tableId)
      }
    } catch (error) {
      console.error(error)
      toast.error((error as Error).message)
    }
  }

  const dispatchGeneralAddRefActions = useCallback(
    async (elem: TextcutRectangle, preValues: any[][]) => {
      await dispatch(addReferece(elem))
      dispatchPushToUndoStack(preValues, elem, 'ADD')
      dispatch(
        setShowSelectionOnlyRange({
          sheetId: elem.sheetId,
          rangeAddress: elem.rangeAddr,
        })
      )
    },
    [dispatch, dispatchPushToUndoStack]
  )

  const snipTable = useCallback(
    async (
      tables: Map<string, TextBlock>,
      cells: Map<string, TextBlock>,
      words: Map<string, TextBlock>,
      tableId: string,
      tablePage: number | null = null
    ) => {
      const table = tables.get(tableId)
      if (!table) throw new Error('Invalid table')
      const intersectedCells: TextBlock[] = []
      table.relationships?.at(0)?.ids.forEach((id) => {
        if (cells.has(id)) {
          intersectedCells.push(cells.get(id)!)
        }
      })
      if (!intersectedCells.length)
        throw new Error(`Invalid table, tableId=${tableId}`)
      const rows = intersectedCells.map((cell) => cell.rowIndex)
      const cols = intersectedCells.map((cell) => cell.columnIndex)
      if (rows.includes(undefined) || cols.includes(undefined))
        throw new Error('Cannot extract table to worksheet')
      const minRowIndex = Math.min(...(rows as number[]))
      const maxRowIndex = Math.max(...(rows as number[]))
      const ROW = maxRowIndex - minRowIndex + 1
      const minColIndex = Math.min(...(cols as number[]))
      const maxColIndex = Math.max(...(cols as number[]))
      const COL = maxColIndex - minColIndex + 1
      const cells2d = convertIntersectedCellToMatrix(
        intersectedCells,
        ROW,
        COL,
        minRowIndex,
        minColIndex
      )
      if (!cells2d.length) throw new Error('Cannot extract table to worksheet')
      let matrix: any[][] = []
      let lines: UserGeneratedLine[] = []
      const [xs, ys] = smoothOcrTable(cells2d, ROW, COL)
      const x1 = xs[0]
      const y1 = ys[0]
      const x3 = xs[xs.length - 1]
      const y3 = ys[ys.length - 1]
      const width = x3 - x1
      const height = y3 - y1
      if (x1 === x3 || y1 === y3)
        throw new Error('Cannot extract table to worksheet')
      for (let i = 1; i < xs.length - 1; i++) {
        const line = [xs[i], y1, xs[i], y1 + height]
        lines.push({ line, id: nanoid() })
      }
      for (let i = 1; i < ys.length - 1; i++) {
        const line = [x1, ys[i], x1 + width, ys[i]]
        lines.push({ line, id: nanoid() })
      }
      const allWordsInCells = getWordsFromCells(intersectedCells, words)
      for (let i = 0; i < ys.length - 1; i++) {
        const arr: any[] = []
        for (let j = 0; j < xs.length - 1; j++) {
          const poly: Polygon = [
            [xs[j] - 1, ys[i] - 1],
            [xs[j + 1] + 1, ys[i] - 1],
            [xs[j + 1] + 1, ys[i + 1] + 1],
            [xs[j] - 1, ys[i + 1] + 1],
          ]
          // console.log('poly:', poly)
          const wordsInCell = allWordsInCells.filter((word) => {
            const wordPoly: Polygon = word.boundingPoly.vertices.map(
              (vertex) => [Math.floor(vertex.x), Math.floor(vertex.y)]
            )
            // console.log(`${word.description}: ${wordPoly}`)
            return (
              polygonInPolygon(wordPoly, poly) ||
              (polygonIntersectsPolygon(wordPoly, poly) &&
                getIntersectionPercentage(wordPoly, poly) >= 0.8)
            )
          })
          const str = sortTextBlockInReadableWay(wordsInCell)
            .map((word) => word.description)
            .join(' ')
          arr[j] = str
        }
        matrix.push(arr)
      }
      // console.log('matrix:', matrix)
      await Excel.run(async (ctx) => {
        const range = ctx.workbook.getSelectedRange()
        const sheet = ctx.workbook.worksheets.getActiveWorksheet()
        range.load('columnIndex')
        range.load('rowIndex')
        sheet.load(['id', 'name'])
        await ctx.sync()
        const [startRow, startCol] = [range.rowIndex, range.columnIndex]
        const [endRow, endCol] = [
          startRow + ys.length - 1 - 1,
          startCol + xs.length - 1 - 1,
        ]

        const matrixRange = `${numberToLetters(startCol)}${startRow + 1
          }:${numberToLetters(endCol)}${endRow + 1}`
        const outputRange = ctx.workbook.worksheets
          .getActiveWorksheet()
          .getRange(matrixRange)
        outputRange.load([
          'rowIndex',
          'columnIndex',
          'rowCount',
          'columnCount',
          'address',
          'values',
        ])

        await ctx.sync()
        const preValues = outputRange.values
        const parsedMatrix = matrix.map((row) =>
          row.map((item) => {
            const date = extractAndParseDate(item)
            const validDate = isValidDate(item)
            return validDate.valid && date ? date : item
          })
        )
        const transposedMatrix = parsedMatrix
        formatTable(outputRange, transposedMatrix as string[][])
        const nano = nanoid()
        const vs = [
          { x: x1, y: y1 },
          { x: x1 + width, y: y1 },
          { x: x1 + width, y: y1 + height },
          { x: x1, y: y1 + height },
        ]
        const topLeft = findTOpLeftPointFromVertices(vs)
        const [h, w] = calculateHeightandWidth(vs[0], vs[1], vs[2], vs[3])
        const [, rangeAddr] = outputRange.address.split('!')
        const top = topLeft?.y ? (topLeft.y / ocrH) * 100 : 0
        const elem = new TextcutRectangleBuilder()
          .addBoudingInfo({
            x: topLeft?.x ?? 0,
            y: topLeft?.y ?? 0,
            h,
            w,
          })
          .addSheetInfo({
            range: rangeAddr,
            sheetId: sheet.id,
            sheetName: sheet.name,
          })
          .addFileInfo({ fileId, filePage: tablePage ?? filePage })
          .addOcrInfo({ height: ocrH, id: '', width: ocrW })
          .addStyle({ cellColor: '#90EE90', fill: '', stroke: 'green' })
          .addBindingId(nano)
          .addDegee(rotationDegree.curr)
          .addTag(CutTag.TABLE)
          .addValues(parsedMatrix)
          .addLines(lines)
          .addTop(top)
          .build()
        const nextRange = sheet.getRangeByIndexes(
          outputRange.rowIndex + outputRange.rowCount,
          outputRange.columnIndex,
          1,
          1
        )
        nextRange.select()
        if (elem) {
          createBindingAndAddOnSelectionHandler(ctx, outputRange, 'Range', nano)
          dispatchGeneralAddRefActions(elem!, preValues)
            .then(() =>
              addMetric({
                namespace: MetricNamespace.TABLECUT,
                metric_value: 1,
                metric_name: MetricName.COUNT,
              })
            )
            .catch((err) => console.error(err))
            .finally(() => {
              setIsSelecting(false)
              setStartPos([-1, -1])
              setEndPos([-1, -1])
              setIsMouseMove(false)
            })
        }
      })
    },
    [
      fileId,
      filePage,
      getWordsFromCells,
      ocrH,
      ocrW,
      dispatchGeneralAddRefActions,
      rotationDegree.curr,
    ]
  )

  const extractAllSystemTables = async () => {
    try {
      const { ocr } = await getFileV2(fileId)
      const [pages, tables, cells, , words] = breakDownOcr(ocr, 0)
      if (!tables.size) return
      const tableMap = new Map<number, string[]>()
      for (const tableId of tables.keys()) {
        const pageNumber = getTablePage(tableId, pages)
        if (!isNaN(pageNumber)) {
          if (tableMap.has(pageNumber)) {
            tableMap.get(pageNumber)!.push(tableId)
          } else {
            tableMap.set(pageNumber, [tableId])
          }
        }
      }
      for (const [pageNumber, tableIds] of tableMap) {
        const ids = [...tableIds]
        ids.sort((a, b) => {
          const tableA = tables.get(a)!
          const tableB = tables.get(b)!
          const minAY = Math.min(
            ...tableA.boundingPoly.vertices.map((vertex) => vertex.y)
          )
          const minBY = Math.min(
            ...tableB.boundingPoly.vertices.map((vertex) => vertex.y)
          )
          if (minAY === minBY) {
            const minAX = Math.min(
              ...tableA.boundingPoly.vertices.map((vertex) => vertex.x)
            )
            const minBX = Math.min(
              ...tableB.boundingPoly.vertices.map((vertex) => vertex.x)
            )
            return minAX - minBX
          }
          return minAY - minBY
        })
        for (const id of ids) {
          await snipTable(tables, cells, words, id, pageNumber)
        }
      }
    } catch (err) {
      console.error(`extractAllSystemTable err=${err}`)
    }
  }

  const layerRightClickHandler = (
    evt: KonvaEventObject<PointerEvent>,
    bindingId: string,
    type: LayerRightClickType
  ) => {
    evt.cancelBubble = true
    const stage = evt.currentTarget.getStage()
    if (!stage) return
    const vector = stage.getPointerPosition()
    const { height, width } = stage.getSize()
    if (!vector) return
    let x = vector.x
    let y = vector.y
    if (x + 80 > width) {
      x -= x + 80 - width
    }
    if (y + 40 > height) {
      y -= y + 40 - height
    }
    setClickedRect(bindingId)
    setContextualMenuPosition([x, y, width, height])
    setShowContextMenu(true)
    setLayerRightClickType(type)
  }

  const onContextMenuDeleteClicked = () => {
    if (clickedRect) {
      // const item = references.find((ref) => ref.bindingId === clickedRect)

      dispatch(deleteReferencesWithBindingId(clickedRect))
        // .then(async () => {
        //   if (item) {
        //     if (item.tag === CutTag.REDACT)
        //       dispatchPushToUndoStack([['']], item, 'DELETE')
        //     else {
        //       const values = await getRangeValues(item.sheetId, item.rangeAddr)
        //       dispatchPushToUndoStack(values, item, 'DELETE')
        //     }
        //   }
        // })
        .catch((err) => console.log(err))
        .finally(() => {
          setClickedRect(undefined)
          setShowContextMenu(false)
          setLayerRightClickType(undefined)
        })
    }
  }

  const onContextMenuCommentClick = () => {
    if (rotationDegree.curr) {
      toast.warning('Comment is diabled when file is rotated.', {
        autoClose: 1000,
      })
    } else {
      setPopupType(LayerRightClickType.STAGE)
      setPopup(true)
    }
    setShowContextMenu(false)
    setLayerRightClickType(undefined)
  }

  const onPopupCancel = () => {
    setPopup(false)
    setPopupType(undefined)
    if (clickedRect) setClickedRect(undefined)
    if (commentTopicId) setCommentTopicId(undefined)
  }

  const onCommentPopupConfirm = (topic: FileCommentTopic | undefined) => {
    setPopup(false)
    setPopupType(undefined)
    const [x, y] = contextMenuPosition
    const convertedX = ratioX.multiply(x).value(),
      convertedY = ratioY.multiply(y).value()
    if (!convertedX || !convertedY || isNaN(convertedX) || isNaN(convertedY))
      return
    if (
      textareaRef.current?.value &&
      textareaRef.current.value.replace(/\s/g, '') &&
      userEmail
    ) {
      const createdAt = dayjs().valueOf()
      const commentTopic: FileCommentTopic = topic
        ? { ...topic }
        : {
          id: nanoid(),
          createdAt,
          comments: [],
          createdBy: userEmail,
          fileId,
          filePage,
          x: convertedX,
          y: convertedY,
          ocrH,
          ocrW,
          degree: rotationDegree.curr,
        }

      const comment: FileCommentContent = {
        content: textareaRef.current.value,
        createdAt,
        createdBy: userEmail,
      }
      commentTopic.comments = [...commentTopic.comments, comment]
      dispatch(addComment(commentTopic)).catch((err) => console.error(err))
    }
  }

  const renderPopup = (setPopup: Dispatch<React.SetStateAction<boolean>>) => {
    if (popupType === LayerRightClickType.REDACT)
      return (
        <Popup
          isOpen={popup}
          popupHeader="Redaction"
          setIsOpen={setPopup}
          secondBtn={{ text: 'Cancel', onClick: onPopupCancel }}
          firstBtn={{ text: 'Confirm', onClick: confirmRedactPopup }}
          popupContent={REDACTION_CONTENT}
        />
      )
    else if (popupType === LayerRightClickType.STAGE) {
      const topic = comments.find((topic) => topic.id === commentTopicId)
      return (
        <Popup
          initialRef={textareaRef}
          isOpen={popup}
          popupHeader={'Comment'}
          setIsOpen={setPopup}
          secondBtn={{ text: 'Cancel', onClick: onPopupCancel }}
          firstBtn={{ text: 'Confirm', onClick: () => { } }}
          popupContent={TextArea(
            textareaRef,
            topic,
            dispatch,
            onCommentPopupConfirm,
            userEmail ? userEmail[0].toLocaleUpperCase() : 'U',
            setPopup
          )}
          hideBtns={true}
          hideHeader={true}
        />
      )
    } else return <></>
  }

  const renderContextMenuItems = (): Pick<
    CustomContextualMenuProps,
    'menuItems'
  > => {
    if (layerRightClickType === LayerRightClickType.REDACT) {
      return {
        menuItems: [
          {
            elem: (
              <button className="flex items-center justify-center">
                <DeleteOutlineOutlinedIcon sx={{ fontSize: 15 }} />
                <h3 className="ml-2">Remove Redaction</h3>
              </button>
            ),
            onClick: onContextMenuDeleteClicked,
          },
        ],
      }
    } else if (layerRightClickType === LayerRightClickType.STAGE) {
      return {
        menuItems: [
          {
            elem: (
              <button className="flex items-center justify-center">
                <ChatBubbleOutlineOutlinedIcon sx={{ fontSize: 15 }} />
                <h3 className="ml-2">Comment</h3>
              </button>
            ),
            onClick: onContextMenuCommentClick,
          },
        ],
      }
    }
    return {
      menuItems: [
        {
          elem: (
            <button className="flex items-center justify-center">
              <DeleteOutlineOutlinedIcon sx={{ fontSize: 15 }} />
              <h3 className="ml-2">Delete</h3>
            </button>
          ),
          onClick: onContextMenuDeleteClicked,
        },
        // {
        //   elem: (
        //     <button className="flex items-center justify-center">
        //       <ChatBubbleOutlineOutlinedIcon sx={{ fontSize: 15 }} />
        //       <h3 className="ml-2">Comment</h3>
        //     </button>
        //   ),
        //   onClick: onContextMenuCommentClick,
        // },
      ],
    }
  }

  const onCommentIconClick = (id: string) => {
    setCommentTopicId(id)
    setPopupType(LayerRightClickType.STAGE)
    setPopup(true)
  }

  const drawCommets = () =>
    comments
      .filter((topic) => topic.fileId === fileId && topic.filePage === filePage)
      .map((topic) => {
        const [x, y] = rotateAVertex(
          topic.x,
          topic.y,
          topic.ocrW,
          topic.ocrH,
          rotationDegree.curr
        )
        const convertedX = numeral(x).divide(ratioX.value()).value() ?? 0
        const convertedY = numeral(y).divide(ratioY.value()).value() ?? 0
        return RenderImage({
          key: topic.id,
          image,
          x: convertedX,
          y: convertedY,
          onClick: onCommentIconClick,
        })
      })

  const removeTableAndCells = (tableId: string) => {
    const table = tables.get(tableId)
    if (!table) return
    const newTables = new Map(tables)
    const newCells = new Map(cells)
    newTables.delete(table.id)
    table.relationships?.forEach((item) =>
      item.ids.forEach((id) => newCells.delete(id))
    )
    setTables(newTables)
    setCells(newCells)
  }

  if (!currentPageOcr) return <></>

  return (
    <>
      {' '}
      {popup && renderPopup(setPopup)}
      {/* {<ToastContainer position="top-center" />} */}
      <Stage
        id="stage"
        className={className ? className : 'absolute'}
        style={style}
        width={width}
        height={height}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onContextMenu={(evt) => {
          layerRightClickHandler(evt, '', LayerRightClickType.STAGE)
        }}
      >
        <DrawLayer
          dataMatchRects={dataMatchRects}
          referenceRect={referenceRect}
          sumRects={sumRects}
          textcutRects={textcutRects}
          fileId={fileId}
          filePage={filePage}
          ratioX={ratioX}
          ratioY={ratioY}
          rotationDegree={rotationDegree}
          showSelectionOnly={showSelectionOnly}
          editTable={editTable}
          wordsBlocks={filteredData.filter(
            (block) => block.blockType === 'WORD'
          )}
          extractionMethod={extractionMethod}
          dispatch={dispatch}
        />

        {(cutType === Cut.TABLECUT || cutType === Cut.TABLES) &&
          cellRects.length > 0 && (
            <TableCellLayer
              cellRects={cellRects}
              ratioX={ratioX}
              ratioY={ratioY}
              tableRects={tableRects}
              onTableClick={onTableClick}
              removeTableAndCells={removeTableAndCells}
              extractAllSystemTables={extractAllSystemTables}
              totalFileTables={tables.size}
            />
          )}

        {isSelecting && (
          <IsSelectingLayer
            x={Math.min(startPos[0], endPos[0])}
            y={Math.min(startPos[1], endPos[1])}
            width={Math.abs(startPos[0] - endPos[0])}
            height={Math.abs(startPos[1] - endPos[1])}
            stroke={getCutTypeStyle(cutType).stroke}
            fill={getCutTypeStyle(cutType).fill}
            strokeWidth={3}
            isFetchingFromBE={isFetchingFromBE}
          />
        )}

        <Layer>
          {/* {textcutTableCellRects.map((r) => (
        <Rect
          key={r.ocrId}
          x={numeral(r.x).divide(ratioX.value()).value() ?? -1}
          y={numeral(r.y).divide(ratioY.value()).value() ?? -1}
          width={numeral(r.w).divide(ratioX.value()).value() ?? -1}
          height={numeral(r.h).divide(ratioY.value()).value() ?? -1}
          stroke={'#B6FBFB'}
        />
      ))} */}

          {drawCommets()}

          {searchAllItemRects.length > 0 &&
            searchAllItemRects.map((r) => (
              <Rect
                key={r.ocrId}
                x={numeral(r.x).divide(ratioX.value()).difference(2) ?? -1}
                y={numeral(r.y).divide(ratioY.value()).difference(2) ?? -1}
                width={numeral(r.w).divide(ratioX.value()).add(4).value() ?? -1}
                height={
                  numeral(r.h).divide(ratioY.value()).add(4).value() ?? -1
                }
                fill={'rgb(230,230,250, 0.3)'}
                stroke={'purple'}
              />
            ))}

          {/* {(cutType === Cut.TABLECUT || cutType === Cut.TABLES) &&
        rects.map((r, i) => (
          <Rect
            key={i}
            x={numeral(r.x).divide(ratioX.value()).value() ?? -1}
            y={numeral(r.y).divide(ratioY.value()).value() ?? -1}
            width={numeral(r.w).divide(ratioX.value()).value() ?? -1}
            height={numeral(r.h).divide(ratioY.value()).value() ?? -1}
            onClick={() => console.log(123123123)}
          />
        ))} */}

          {/* {(cutType === Cut.WORD || cutType === Cut.LINE) &&
        rects.map((r, i) => (
          <Rect
            key={i}
            x={numeral(r.x).divide(ratioX.value()).difference(2) ?? -1}
            y={numeral(r.y).divide(ratioY.value()).difference(2) ?? -1}
            width={numeral(r.w).divide(ratioX.value()).add(4).value() ?? -1}
            height={
              numeral(r.h).divide(ratioY.value()).add(4).value() ?? -1
            }
            fill={hoverIdx === i ? 'rgb(241,241,241, 0.5)' : ''}
            stroke={
              r.stroke ? r.stroke : strokeIdx === i ? '#B6FBFB' : r.stroke
            }
            onMouseEnter={() => {
              setHoverIdx(i)
              setStrokeIdx(i)
            }}
            onMouseLeave={() => {
              setHoverIdx(-1)
              setStrokeIdx(i)
            }}
            onClick={onHoveredClick(r)}
          />
        ))} */}

          {redactRects.length > 0 &&
            redactRects
              .filter(
                (rect) => rect.fileId === fileId && rect.filePage === filePage
              )
              .map((rect) => {
                if (rect.degree !== rotationDegree.curr) {
                  const targetDegree =
                    (((rotationDegree.curr - rect.degree) % 360) + 360) % 360
                  const input = convertToFourPointVertices(
                    rect.x,
                    rect.y,
                    rect.w,
                    rect.h
                  )
                  const arr = rotateOcrBox(
                    input,
                    targetDegree,
                    rect.ocrW,
                    rect.ocrH
                  )
                  const topLeft = findTOpLeftPointFromVertices(arr)
                  const [hh, ww] = calculateHeightandWidth(
                    arr[0],
                    arr[1],
                    arr[2],
                    arr[3]
                  )
                  if (!topLeft) return rect
                  rect = { ...rect, x: topLeft.x, y: topLeft.y, h: hh, w: ww }
                  return rect
                } else {
                  return rect
                }
              })
              .map((r: TextcutRectangle, i: number) => (
                <Rect
                  key={r.bindingId}
                  x={numeral(r.x).divide(ratioX.value()).difference(2) ?? -1}
                  y={numeral(r.y).divide(ratioY.value()).difference(2) ?? -1}
                  width={
                    numeral(r.w).divide(ratioX.value()).add(4).value() ?? -1
                  }
                  height={
                    numeral(r.h).divide(ratioY.value()).add(4).value() ?? -1
                  }
                  fill={r.fill}
                  stroke={r.stroke}
                  onClick={() => setShowContextMenu(false)}
                  onContextMenu={(evt) => {
                    layerRightClickHandler(
                      evt,
                      r.bindingId,
                      LayerRightClickType.REDACT
                    )
                  }}
                />
              ))}
        </Layer>
      </Stage>
      {showContextMenu && layerRightClickType && (
        <CustomContextualMenu
          x={contextMenuPosition[0]}
          y={contextMenuPosition[1]}
          menuItems={renderContextMenuItems().menuItems}
          canvasHeight={contextMenuPosition[3]}
          canvasWidth={contextMenuPosition[2]}
        />
      )}
    </>
  )
}

export default KonvaContainer
