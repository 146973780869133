import ErrorIcon from '@mui/icons-material/Error'
import { useNavigate } from 'react-router-dom'

type Props = {
  title: string
  content: string
  navigate?: ReturnType<typeof useNavigate>
  refetch?: () => Promise<any>
  reset?: (...args: any[]) => any
  error?: Error | null
}

const ErrorMsg = ({ title, content, navigate, error, refetch, reset }: Props) => {
  const onClick = async () => {
    try {
      if (navigate) {
        navigate(0)
      } else if (refetch) {
        await refetch()
      } else if (reset) {
        reset()
      }
    } catch (error) {
      console.error(`ErrorMsg err=${error}`)
    }
  }

  return (
    <>
      <div className="flex flex-col w-full justify-center items-center bg-white p-4 rounded-lg">
        <div>
          <ErrorIcon sx={{ color: 'red', fontSize: 30 }} />
        </div>
        <div className="text-sm font-bold">{title}</div>
        <div className="text-sm font-semibold">{content}</div>

        {(navigate || refetch || reset) && (
          <div className="flex w-full justify-center mt-1">
            <button
              onClick={onClick}
              className="text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-teal-300 font-medium rounded-full text-xs px-2 py-2 text-center"
            >
              Reload
            </button>
          </div>
        )}
      </div>
      {error && (
        <div className="flex flex-col items-center justify-center w-full bg-white p-4 rounded-lg mt-2 overflow-hidden">
          <div className="text-sm font-bold">Error message</div>
          <div>
            <pre className="w-full max-w-[85vw] text-xs whitespace-pre-wrap overflow-auto text-left">
              {error.message}
            </pre>
          </div>
        </div>
      )}
    </>
  )
}

export default ErrorMsg
