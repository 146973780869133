import { compressToUTF16, decompressFromUTF16 } from 'lz-string'
import { getFile } from '../api'
import {
  CompressedFile,
  CutTag,
  DynamoFile,
  FileRecord,
  FileStatus,
  TestDataOcr,
  UndoEvent,
} from '../types'
import {
  DISABLED_RIBBON_BUTTON,
  ENABLED_RIBBON_BUTTON_AFTER_SEARCH_ALL,
  NO_OUTPUT,
} from '../constant'
import {
  fileSizeLessThan5MB,
  publishBindingOnDataChangeEvent,
  publishOnReferenceSelectedEvent,
} from '../utils/common'
import dayjs from 'dayjs'
import { breakDownOcr } from '../utils/ocr'
import { getDateBlocks } from '../utils/fuzzy'
import { addFileToDB, removeFileFromDB } from '../utils/db'

/**
 * Test function, set values a range of cells
 */
export const setValuesForRangeDemo = async () => {
  await Excel.run(async (ctx) => {
    const sheet = ctx.workbook.worksheets.getActiveWorksheet()
    const data = [['foo', 'bar', 'qux']]
    const range = sheet.getRange('B5:D5')
    range.values = data
    await ctx.sync()
  })
}

export const setValueToSelectedRange = async (val: string) => {
  return await Excel.run(async (ctx) => {
    const sheet = ctx.workbook.worksheets.getActiveWorksheet()
    sheet.load('id')
    const range = ctx.workbook.getSelectedRange()
    range.load('values')
    range.load('rowIndex')
    range.load('columnIndex')
    await ctx.sync()
    const values = range.values
    values[0][0] = val
    range.values = values
    range.format.autofitColumns()
    const row = range.rowIndex
    const col = range.columnIndex
    await ctx.sync()
    return `${sheet.id}/${numberToLetters(col)}${row + 1}`
  })
}

export const generateExcelColumns = (): string[] => {
  const arr: string[] = [NO_OUTPUT]
  for (let i = 0; i < 50; i++) arr.push(numberToLetters(i))

  return arr
}

export const numberToLetters = (num: number) => {
  let letters = ''
  while (num >= 0) {
    letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[num % 26] + letters
    num = Math.floor(num / 26) - 1
  }
  return letters
}

export const setMatrixToSelectedRange = async (
  matrix: string[][],
  row: number,
  col: number
) => {
  return await Excel.run(async (ctx) => {
    const range = ctx.workbook.getSelectedRange()
    const sheet = ctx.workbook.worksheets.getActiveWorksheet()
    range.load('columnIndex')
    range.load('rowIndex')
    sheet.load('id')
    await ctx.sync()
    const [startRow, startCol] = [range.rowIndex, range.columnIndex]
    const [endRow, endCol] = [startRow + row - 1, startCol + col - 1]

    const matrixRange = `${numberToLetters(startCol)}${startRow + 1
      }:${numberToLetters(endCol)}${endRow + 1}`

    const outputRange = ctx.workbook.worksheets
      .getActiveWorksheet()
      .getRange(matrixRange)
    outputRange.values = matrix
    outputRange.format.autofitColumns()
    outputRange.format.autofitRows()
    await ctx.sync()
    return `${sheet.id}/${matrixRange}`
  })
}

export const generateReferenceId = (sheetId: string, cellAddr: string) =>
  `REFERENCE>>${sheetId}>>${cellAddr}`

export const lettersToNumber = (letters: string) => {
  let n = 0
  for (let p = 0; p < letters.length; p++) {
    n = letters[p].charCodeAt(0) - 64 + n * 26
  }
  return n
}

export const cellInRange = (cellAddr: string, rangeAddr: string) => {
  try {
    const cellCol = lettersToNumber(cellAddr[0])
    const cellRow = Number(cellAddr.substring(1, cellAddr.length))
    const [head, tail] = rangeAddr.split(':')
    const headCol = lettersToNumber(head[0])
    const headRow = Number(head.substring(1, head.length))
    const tailCol = lettersToNumber(tail[0])
    const tailRow = Number(tail.substring(1, tail.length))
    return (
      cellCol >= headCol &&
      cellCol <= tailCol &&
      cellRow >= headRow &&
      cellRow <= tailRow
    )
  } catch (err) {
    console.log('err:', err)
    return false
  }
}

/**
 * Loads values from a specified range in the workbook.
 * @param range - The range to load values from.
 *
 * @returns A promise that resolves to the loaded values from the range.
 */
export const loadValuesFromARange = (range: string) => {
  return Excel.run(async (ctx) => {
    const sheet = ctx.workbook.worksheets.getActiveWorksheet()
    const r = sheet.getRange(range)
    // r.load('values')
    r.load('text') // using text instead of values due to formatting issues
    await ctx.sync()
    // return r.values
    return r.text.map((row) => row.map((text) => text.trim()))
  })
}

export const loadTextFromRange = (range: string, outputRange: string[]) =>
  Excel.run(async (ctx) => {
    const sheet = ctx.workbook.worksheets.getActiveWorksheet()
    const r = sheet.getRange(range)
    r.load(['text', 'values'])
    await ctx.sync()
    const noOuputIndices: number[] = []
    outputRange.forEach((r, i) => {
      if (r === NO_OUTPUT) noOuputIndices.push(i)
    })
    const arr = r.text.map((row) => row.map((text) => text.trim()))
    const valueArr = r.values.map((row) => row.map((val) => val))
    const result: string[][] = new Array(arr.length).fill(null).map((_) => [])
    const valueResult: any[][] = new Array(arr.length).fill(null).map((_) => [])
    for (let i = 0; i < arr.length; i++) {
      for (let j = 0; j < arr[i].length; j++) {
        if (noOuputIndices.includes(j)) continue
        result[i].push(arr[i][j])
        valueResult[i].push(valueArr[i][j])
      }
    }
    return {
      textResult: result,
      valueResult: valueResult,
    }
  })

// const decode = (buffer: ArrayBuffer) => {
//   const decoder = new TextDecoder()
//   return decoder.decode(buffer)
// }

// const encode = (str: string) => {
//   const encoder = new TextEncoder()
//   return encoder.encode(str).buffer
// }

const attachPDFsToWorkbook = (files: CompressedFile[]) =>
  Excel.run(async (ctx) => {
    const settings = ctx.workbook.settings
    files.forEach((file) => {
      const k = `FILE_${file.fileId}`
      settings.add(k, JSON.stringify(file))
    })
    await ctx.sync()
  })

const pdfToBase64 = (blob: Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result as string)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })

const base64ToBlob = (base64: string, type: string) => {
  const chars = atob(base64.split(',')[1])
  const numbers = new Array(chars.length)
  for (let i = 0; i < chars.length; i++) {
    numbers[i] = chars.charCodeAt(i)
  }
  const byteArray = new Uint8Array(numbers)
  return new Blob([byteArray], { type })
}

export const savePdfsToWorkbook = async (files: DynamoFile[]) => {
  const [cloudFileRecords] = await getLocalFileRecords()
  const filteredFiles = files.filter(
    (file) =>
      cloudFileRecords.some((re) => re.fileId === file.fileId) &&
      fileSizeLessThan5MB(file.originalFileSize)
  )
  const filteredFileIdSet = new Set(filteredFiles.map((file) => file.fileId))
  const promises = filteredFiles.map(async (file) => {
    const [pdfBlob, ocrJSON] = await getFile(file.fileId)
    const compressedOCR = compressToUTF16(JSON.stringify(ocrJSON))
    const pdfBase64 = await pdfToBase64(pdfBlob)
    const compressedPDF = compressToUTF16(pdfBase64)
    const ocrSize = new Blob([compressedOCR]).size

    return {
      ...file,
      compressedPDF,
      compressedOCR,
      originalFileSize: pdfBlob.size + ocrSize,
    }
  })
  const newFileRecords = cloudFileRecords.map((record) => {
    if (filteredFileIdSet.has(record.fileId)) {
      return { ...record, isLocal: true }
    }
    return record
  })
  // console.log('newFileRecords:', newFileRecords)
  await updateLocalFileRecords(newFileRecords)
  const arr = await Promise.all(promises)
  await attachPDFsToWorkbook(arr)
}

export const fetchPDFsFromWorkbook = () =>
  Excel.run(async (ctx) => {
    const settings = ctx.workbook.settings
    settings.load('items')
    await ctx.sync()
    const items = settings.items
    const settingArray: Excel.Setting[] = []
    for (const setting of items) {
      settingArray.push(setting)
      setting.load(['value', 'key', 'isNullObject'])
    }
    await ctx.sync()
    let arr: DynamoFile[] = []
    settingArray
      .filter((item) => !item.isNullObject && item.key.startsWith('FILE_'))
      .forEach((item) => {
        const parsedValue: CompressedFile = JSON.parse(item.value)
        arr.push({
          completedAt: parsedValue.completedAt,
          createdAt: parsedValue.createdAt,
          fileId: parsedValue.fileId,
          fileType: parsedValue.fileType,
          type: parsedValue.type,
          folderName: parsedValue.folderName,
          parentFolderId: parsedValue.parentFolderId,
          ocrFileKey: parsedValue.ocrFileKey,
          originalFileKey: parsedValue.originalFileKey,
          status: parsedValue.status,
          userId: parsedValue.userId,
          fileName: parsedValue.fileName,
          originalFileSize: parsedValue.originalFileSize,
          dir: parsedValue.dir,
          extractionEngine: parsedValue.extractionEngine,
          extractionMethod: parsedValue.extractionMethod,
        })
      })
    // if (!item.isNullObject) {
    //   const parsedValue: CompressedFile[] = JSON.parse(item.value)
    //   for (const file of parsedValue) {
    //     arr.push({
    //       completedAt: file.completedAt,
    //       createdAt: file.createdAt,
    //       fileId: file.fileId,
    //       fileType: file.fileType,
    //       ocrFileKey: file.ocrFileKey,
    //       originalFileKey: file.originalFileKey,
    //       status: file.status,
    //       userId: file.userId,
    //       fileName: file.fileName,
    //     })
    //   }
    // }
    return arr.sort((a, b) => dayjs(b.createdAt).diff(dayjs(a.createdAt)))
  })

export const fetchPDFFromLocal = (id: string) =>
  Excel.run(async (ctx) => {
    const settings = ctx.workbook.settings
    settings.load('items')
    await ctx.sync()
    const items = settings.items
    const settingArray: Excel.Setting[] = []
    for (const setting of items) {
      settingArray.push(setting)
      setting.load(['value', 'key', 'isNullObject'])
    }
    await ctx.sync()
    const filteredSettingArray = settingArray.filter(
      (setting) => !setting.isNullObject && setting.key.startsWith('FILE_')
    )
    for (const item of filteredSettingArray) {
      const key = item.key
      const value = item.value
      const [, fileId] = key.split('_')

      if (fileId !== id) continue
      const parsedValue: CompressedFile = JSON.parse(value)
      const decompressedOCR = decompressFromUTF16(parsedValue.compressedOCR)
      const ocr: TestDataOcr = JSON.parse(decompressedOCR)
      const decompressedPDF = decompressFromUTF16(parsedValue.compressedPDF)
      const pdfBlob = base64ToBlob(decompressedPDF, 'application/pdf')
      return { ...parsedValue, ocr, pdfBlob }
    }
    return null
  })

export const fetchPDFBatchFromLocal = (ids: string[]) =>
  Excel.run(async (ctx) => {
    const settings = ctx.workbook.settings
    settings.load('items')
    await ctx.sync()
    const items = settings.items
    const settingArray: Excel.Setting[] = []
    for (const setting of items) {
      settingArray.push(setting)
      setting.load(['value', 'key', 'isNullObject'])
    }
    await ctx.sync()
    const filteredSettingArray = settingArray.filter(
      (setting) => !setting.isNullObject && setting.key.startsWith('FILE_')
    )
    const arr: (CompressedFile & { ocr: TestDataOcr; pdfBlob: Blob })[] = []
    for (const item of filteredSettingArray) {
      const key = item.key
      const value = item.value
      const [, fileId] = key.split('_')

      if (ids.includes(fileId)) {
        const parsedValue: CompressedFile = JSON.parse(value)
        const decompressedOCR = decompressFromUTF16(parsedValue.compressedOCR)
        const ocr: TestDataOcr = JSON.parse(decompressedOCR)
        const decompressedPDF = decompressFromUTF16(parsedValue.compressedPDF)
        const pdfBlob = base64ToBlob(decompressedPDF, 'application/pdf')
        arr.push({ ...parsedValue, ocr, pdfBlob })
      }
    }
    return arr
  })

export const removeAttachedFile = (id: string) =>
  Excel.run(async (ctx) => {
    await removeFileFromDB(id)
    const settingId = `FILE_${id}`
    const settings = ctx.workbook.settings
    const item = settings.getItemOrNullObject(settingId)
    item.load('isNullObject')
    await ctx.sync()
    if (!item.isNullObject) {
      item.delete()
      await ctx.sync()
    }
  })

export const autoSave = () =>
  Excel.run(async (ctx) => {
    await ctx.sync()
    ctx.workbook.save()
  })

export const replaceLocalPDF = (
  file: CompressedFile & { ocr: TestDataOcr; pdfBlob: Blob }
) =>
  Excel.run(async (ctx) => {
    const compressedOCR = compressToUTF16(JSON.stringify(file.ocr))
    const ocrSize = new Blob([compressedOCR]).size
    const pdfBase64 = await pdfToBase64(file.pdfBlob)
    const compressedPDF = compressToUTF16(pdfBase64)
    const settings = ctx.workbook.settings
    const k = `FILE_${file.fileId}`
    const item = settings.getItemOrNullObject(k)
    item.load(['value', 'isNullObject'])
    await ctx.sync()
    if (item.isNullObject) return
    const value: CompressedFile = JSON.parse(item.value)
    const newValue = {
      ...value,
      compressedOCR,
      compressedPDF,
      originalFileSize: file.pdfBlob.size + ocrSize,
    }
    settings.add(k, JSON.stringify(newValue))
    await ctx.sync()
    ctx.workbook.save()
  })

export const saveFileRecordsToLocal = (fileRecords: FileRecord[]) =>
  Excel.run(async (ctx) => {
    const settings = ctx.workbook.settings
    const item = settings.getItemOrNullObject('FileRecords')
    item.load(['value', 'isNullObject'])
    await ctx.sync()
    if (item.isNullObject) {
      settings.add('FileRecords', JSON.stringify(fileRecords))
    } else {
      const elems: FileRecord[] = JSON.parse(item.value)
      const arr = [...elems, ...fileRecords]
      settings.add('FileRecords', JSON.stringify(arr))
    }
    await ctx.sync()
    ctx.workbook.save()
  })

export const updateLocalFileRecords = (fileRecords: FileRecord[]) =>
  Excel.run(async (ctx) => {
    const settings = ctx.workbook.settings
    const item = settings.getItemOrNullObject('FileRecords')
    item.load(['value', 'isNullObject'])
    await ctx.sync()
    if (item.isNullObject) {
      settings.add('FileRecords', JSON.stringify(fileRecords))
    } else {
      const elems: FileRecord[] = JSON.parse(item.value)
      const arr: [string, FileRecord][] = fileRecords.map((record) => [
        record.fileId,
        record,
      ])
      const map = new Map(arr)
      const mapped: FileRecord[] = elems.map((elem) => {
        if (map.has(elem.fileId)) {
          const item = map.get(elem.fileId)!
          const newElem: FileRecord = {
            ...elem,
            status: item.status,
            isLocal: item.isLocal,
          }
          return newElem
        }
        return elem
      })
      // const filtered = mapped.filter(
      //   (elem) => elem.status !== FileStatus.FAILED
      // )
      // console.log('mapped:', mapped)
      settings.add('FileRecords', JSON.stringify(mapped))
    }
    await ctx.sync()
    ctx.workbook.save()
  })

export const containProcessingRecord = (): Promise<[boolean, FileRecord[]]> =>
  Excel.run(async (ctx) => {
    const settings = ctx.workbook.settings
    const item = settings.getItemOrNullObject('FileRecords')
    item.load(['value', 'isNullObject'])
    await ctx.sync()
    if (item.isNullObject) return [false, []]
    const elems: FileRecord[] = JSON.parse(item.value)
    const arr = elems.filter((elem) => elem.status === FileStatus.PROCESSING)
    return [arr.length > 0 ? true : false, arr]
  })

export const getLocalFileRecords = (): Promise<[FileRecord[], FileRecord[]]> =>
  Excel.run(async (ctx) => {
    const settings = ctx.workbook.settings
    const item = settings.getItemOrNullObject('FileRecords')
    item.load(['value', 'isNullObject'])
    await ctx.sync()
    if (item.isNullObject) return [[], []]
    else {
      const elems: FileRecord[] = JSON.parse(item.value)
      const localRFecords = elems.filter((elem) => elem.isLocal)
      const cloudRecords = elems.filter((elem) => !elem.isLocal)
      return [cloudRecords, localRFecords]
    }
  })

export const findLocalFileRecordWithFileId = (fileId: string) =>
  Excel.run(async (ctx) => {
    const settings = ctx.workbook.settings
    const item = settings.getItemOrNullObject('FileRecords')
    item.load(['value', 'isNullObject'])
    await ctx.sync()
    if (item.isNullObject) return undefined
    else {
      const elems: FileRecord[] = JSON.parse(item.value)
      return elems.find((elem) => elem.fileId === fileId)
    }
  })

export const findLocalFileRecordWithFileIds = (fileIds: string[]) =>
  Excel.run(async (ctx) => {
    const settings = ctx.workbook.settings
    const item = settings.getItemOrNullObject('FileRecords')
    item.load(['value', 'isNullObject'])
    await ctx.sync()
    if (item.isNullObject) return []
    const elems: FileRecord[] = JSON.parse(item.value)
    return elems.filter((elem) => fileIds.includes(elem.fileId))
  })

export const removeLocalFileRecordWithIds = (fileIds: string[]) =>
  Excel.run(async (ctx) => {
    const settings = ctx.workbook.settings
    const item = settings.getItemOrNullObject('FileRecords')
    item.load(['value', 'isNullObject'])
    await ctx.sync()
    if (item.isNullObject) return
    const elems: FileRecord[] = JSON.parse(item.value)
    const filtered = elems.filter((elem) => !fileIds.includes(elem.fileId))
    settings.add('FileRecords', JSON.stringify(filtered))
    await ctx.sync()
    ctx.workbook.save()
  })

export const resetLocalFileRecord = () =>
  Excel.run(async (ctx) => {
    const settings = ctx.workbook.settings
    const item = settings.getItemOrNullObject('FileRecords')
    item.load(['value', 'isNullObject'])
    await ctx.sync()
    if (item.isNullObject) return
    const arr: FileRecord[] = []
    settings.add('FileRecords', JSON.stringify(arr))
    await ctx.sync()
    ctx.workbook.save()
  })

export const undoRange = (event: UndoEvent) =>
  Excel.run(async (ctx) => {
    const sheet = ctx.workbook.worksheets.getItemOrNullObject(
      event.reference.sheetId
    )
    sheet.load('isNullObject')
    await ctx.sync()
    if (sheet.isNullObject) return
    sheet.activate()
    const range = sheet.getRange(event.reference.rangeAddr)
    range.values = event.preValues
    range.format.fill.clear()
    if (event.reference.tag === CutTag.TABLE) {
      range.format.borders.getItem('EdgeBottom').style = 'None'
      range.format.borders.getItem('EdgeLeft').style = 'None'
      range.format.borders.getItem('EdgeRight').style = 'None'
      range.format.borders.getItem('EdgeTop').style = 'None'
    }
    range.select()
    await ctx.sync()
  })

export const getRangeValues = (sheetId: string, rangeAddress: string) =>
  Excel.run(async (ctx) => {
    const sheet = ctx.workbook.worksheets.getItemOrNullObject(sheetId)
    sheet.load('isNullObject')
    await ctx.sync()
    if (sheet.isNullObject) throw new Error('Invalid range')
    const range = sheet.getRange(rangeAddress)
    range.load('values')
    await ctx.sync()
    return range.values
  })

export const getRangeText = (sheetId: string, rangeAddress: string) =>
  Excel.run(async (ctx) => {
    const sheet = ctx.workbook.worksheets.getItemOrNullObject(sheetId)
    sheet.load('isNullObject')
    await ctx.sync()
    if (sheet.isNullObject) throw new Error('Invalid range')
    const range = sheet.getRange(rangeAddress)
    range.load('text')
    await ctx.sync()
    return range.text
  })

export const setRangeValues = (
  sheetId: string,
  rangeAddress: string,
  values: any[][]
) =>
  Excel.run(async (ctx) => {
    const sheet = ctx.workbook.worksheets.getItemOrNullObject(sheetId)
    sheet.load('isNullObject')
    await ctx.sync()
    if (sheet.isNullObject) throw new Error('Invalid range')
    const range = sheet.getRange(rangeAddress)
    range.values = values
    sheet.activate()
    range.select()
    await ctx.sync()
  })

export const disableRibbon = () =>
  Office.ribbon.requestUpdate(DISABLED_RIBBON_BUTTON)

export const enableRibbonAfterSearchAll = () =>
  Office.ribbon.requestUpdate(ENABLED_RIBBON_BUTTON_AFTER_SEARCH_ALL)

export const removeInvalidBindings = () =>
  Excel.run(async (ctx) => {
    const ids: string[] = []
    const bindings = ctx.workbook.bindings
    bindings.load('items')
    await ctx.sync()
    for (const item of bindings.items) {
      item.load('id')
      await ctx.sync().catch(() => ids.push(item.id))
    }
    console.log('ids:', ids)
    for (const id of ids) Office.context.document.bindings.releaseByIdAsync(id)
    await ctx.sync()
    return ids
  })

export const releaseBindingIdAsync = (id: string) =>
  new Promise((resolve, reject) =>
    Office.context.document.bindings.releaseByIdAsync(id, (result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        resolve(result)
      } else {
        reject(result)
      }
    })
  )

export const createBindingAndAddOnSelectionHandler = (
  ctx: Excel.RequestContext,
  range: Excel.Range,
  bindingType: 'Range' | 'Table' | 'Text',
  bindingId: string
) => {
  const binding = ctx.workbook.bindings.add(range, bindingType, bindingId)
  binding.onSelectionChanged.add(async (args) => {
    args.binding.load('id')
    await args.binding.context.sync()
    publishOnReferenceSelectedEvent(args.binding.id)
  })

  binding.onDataChanged.add(async (args) => {
    args.binding.load('id')
    await args.binding.context.sync()
    publishBindingOnDataChangeEvent(args.binding.id)
  })
  return binding
}

export const deleteBinding = (bindingId: string) =>
  Excel.run(async (ctx) => {
    const binding = ctx.workbook.bindings.getItemOrNullObject(bindingId)
    binding.load(['id', 'isNullObject'])
    await ctx
      .sync()
      .catch(() =>
        Office.context.document.bindings.releaseByIdAsync(binding.id)
      )
    if (binding.isNullObject) return
    binding.delete()
    await ctx.sync()
  })

export const recreateBinding = (bindingId: string, newBindingId: string, sheetId: string, rangeAddr: string) => Excel.run(async ctx => {
  const binding = ctx.workbook.bindings.getItemOrNullObject(bindingId)
  binding.load(['id', 'isNullObject'])
  await ctx
    .sync()
    .catch(() =>
      Office.context.document.bindings.releaseByIdAsync(binding.id)
    )
  if (!binding.isNullObject) {
    binding.delete()
  }
  await ctx.sync()

  const sheet = ctx.workbook.worksheets.getItem(sheetId)
  const range = sheet.getRange(rangeAddr)
  createBindingAndAddOnSelectionHandler(ctx, range, 'Range', newBindingId)
  await ctx.sync()
})

export const isEmptyRange = (bindingId: string) =>
  Excel.run(async (ctx) => {
    const binding = ctx.workbook.bindings.getItemOrNullObject(bindingId)
    binding.load(['isNullObject'])
    await ctx.sync()
    if (binding.isNullObject) {
      return true
    }

    const range = binding.getRange()
    range.load(['values'])
    await ctx.sync()
    return range.values.every((row) => row.every((value) => value === ''))
  })
export const getColumnIndex = (address: string, firstRowHeader: 0 | 1) =>
  Excel.run(async (ctx) => {
    if (address === '') return
    const sheet = ctx.workbook.worksheets.getActiveWorksheet()
    const range = sheet.getRange(address)
    range.load(['columnCount', 'columnIndex', 'rowIndex'])
    await ctx.sync()
    const columns = []
    for (
      let i = range.columnIndex;
      i < range.columnIndex + range.columnCount;
      i++
    ) {
      columns.push(numberToLetters(i))
    }
    if (!firstRowHeader) return columns

    const ranges: Excel.Range[] = []

    for (
      let i = range.columnIndex;
      i < range.columnIndex + range.columnCount;
      i++
    ) {
      const r = sheet.getRangeByIndexes(range.rowIndex, i, 1, 1)
      r.load('values')
      ranges.push(r)
    }

    await ctx.sync()
    return columns.map((val, i) => `${val} | ${ranges[i].values[0][0]}`)
  })

export const clearBindingForDataMatch = async (
  ctx: Excel.RequestContext,
  sheet: Excel.Worksheet,
  rowIndex: number,
  rowCount: number,
  outputRange: string[]
) => {
  if (rowCount <= 0) return
  const formattedRanges = outputRange.map((col) => {
    if (col === NO_OUTPUT) return null
    if (rowCount === 1) return `${col}${rowIndex + 1}`
    else return `${col}${rowIndex + 1}:${col}${rowIndex + 1 + rowCount}`
  })
  const filteredFormattedRanges = formattedRanges.filter(
    (range) => range !== null
  )
  const selectedRanges = sheet.getRanges(filteredFormattedRanges.join(', '))
  sheet.load('id')
  ctx.workbook.bindings.load('items')
  await ctx.sync()
  const bindings = ctx.workbook.bindings.items
  const ranges = bindings.map((b) => b.getRange())
  ranges.forEach((range) => range.worksheet.load('id'))
  await ctx.sync()
  const filteredRanges = ranges.filter(
    (range) => range.worksheet.id === sheet.id
  )
  const bindingIntersections = filteredRanges.map((range) =>
    selectedRanges.getIntersectionOrNullObject(range)
  )
  bindingIntersections.forEach((intersection) =>
    intersection.load('isNullObject')
  )
  await ctx.sync()
  for (let i = 0; i < bindingIntersections.length; i++) {
    if (!bindingIntersections[i].isNullObject) {
      console.log('DELETED!!!')
      bindings[i].delete()
    }
  }
  await ctx.sync()
}

export const formatTable = (table: Excel.Range, values: string[][]) => {
  table.values = values
  table.format.borders.getItem('EdgeBottom').style = 'Continuous'
  table.format.borders.getItem('EdgeLeft').style = 'Continuous'
  table.format.borders.getItem('EdgeRight').style = 'Continuous'
  table.format.borders.getItem('EdgeTop').style = 'Continuous'
  table.format.autofitColumns()
  table.format.autofitRows()
  table.format.font.name = 'Segoe UI'
  table.format.fill.color = '#90EE90'
}

export const enableEvents = () =>
  Excel.run(async (ctx) => {
    ctx.runtime.enableEvents = true
    await ctx.sync()
  })

export const cacheLocalFile = async (file: CompressedFile) => {
  const decompressedOCR = decompressFromUTF16(file.compressedOCR)
  const ocr: TestDataOcr = JSON.parse(decompressedOCR)
  const decompressedPDF = decompressFromUTF16(file.compressedPDF)
  const pdfBlob = base64ToBlob(decompressedPDF, 'application/pdf')
  const [, , , lines, words] = breakDownOcr(ocr, 0)
  const blocks = [...lines.values(), ...words.values()]
  const dateBlocks = getDateBlocks(blocks)
  await addFileToDB({
    id: file.fileId,
    type: file.type,
    folderName: file.folderName,
    parentFolderId: file.parentFolderId,
    createdAt: new Date(),
    extractionMethod: file.extractionMethod,
    fileName: file.fileName,
    ocr,
    ocrDateCache: dateBlocks,
    pdf: pdfBlob,
    extractionEngine: file.extractionEngine,
  })
}

export const getColumnSize = (range: string) =>
  Excel.run(async (ctx) => {
    try {
      const sheet = ctx.workbook.worksheets.getActiveWorksheet()
      const r = sheet.getRange(range)
      r.load(['isNullObject', 'columnCount'])
      await ctx.sync()
      if (r.isNullObject) return 0
      return r.columnCount
    } catch (error) {
      return 0
    }
  })
