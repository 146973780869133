import { ErrorBoundary } from "react-error-boundary"
import FileContainer from "../components/FileContainer"
import CustomErrorBoundary from "../components/CustomErrorBoundary"

const FilePage = () => {
    return <ErrorBoundary
        fallbackRender={({ error, resetErrorBoundary }) => <CustomErrorBoundary
            error={error}
            resetErrorBoundary={resetErrorBoundary} />}>
        <FileContainer />
    </ErrorBoundary>
}

export default FilePage