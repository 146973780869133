import { ChangeEvent, RefObject } from 'react'

type InputRangeElemProps = {
  inputRangeRef: RefObject<HTMLInputElement>
  inputRange: string
  onInputRangeChange: (event: ChangeEvent<HTMLInputElement>) => void
  firstRowHeader: 0 | 1
  allowStepTwo: () => void
  setFirstRowHeader: (value: React.SetStateAction<0 | 1>) => void
}

const InputRangeElem = ({
  allowStepTwo,
  inputRange,
  inputRangeRef,
  firstRowHeader,
  onInputRangeChange,
  setFirstRowHeader,
}: InputRangeElemProps) => (
  <>
    <div className="bg-white rounded p-1">
      <input
        id="InputRange"
        ref={inputRangeRef}
        type="text"
        className="w-full h-7  my-2 p-1 px-2 border-2 rounded  text-black border-b-[#616161] "
        value={`= ${inputRange}`}
        onChange={onInputRangeChange}
      />
      <div className="flex items-center justify-between">
        <div>
          <input
            type="checkbox"
            value={firstRowHeader}
            checked={firstRowHeader === 1}
            onChange={() => setFirstRowHeader(firstRowHeader ? 0 : 1)}
            onClick={() => setFirstRowHeader(firstRowHeader === 0 ? 1 : 0)}
            size={20}
            className="mr-2 accent-[#107C41]"
          />
          <label htmlFor="include-heading">First row including heading</label>
        </div>
      </div>

      <div className="flex flex-row-reverse py-2">
        <button
          className="min-w-16 inline-flex text-white m-1 justify-center rounded-full px-2 py-1 sm:px-4 sm:py-2 text-xs sm:text-sm font-medium bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br"
          onClick={allowStepTwo}
        >
          Next
        </button>
      </div>
    </div>
  </>
)

export default InputRangeElem
