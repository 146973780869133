import { Dispatch, useEffect, useState } from 'react'
import { EPlusFolder, FileStructure, TestFileCheck } from '../../types'
import { Disclosure, Transition } from '@headlessui/react'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'

type TreeProps = {
  fileStructure: FileStructure
  files: TestFileCheck[]
  folders: EPlusFolder[]
  setFiles: Dispatch<React.SetStateAction<TestFileCheck[]>>
  setSelectedFolders: Dispatch<React.SetStateAction<string[]>>
  selectedFolders: string[]
  searchMode: 0 | 1 | 2
}

// interface TreeNodePros extends Omit<TreeProps, 'fileStructure'> {
//   fileStructure: FileStructure | null
//   isFile: boolean
//   current: string
// }

type FileTreeNodeProps = {
  file: TestFileCheck | undefined
  checkFiles: (ids: string[], checked: boolean) => void
  setSelectedFolders: Dispatch<React.SetStateAction<string[]>>
  selectedFolders: string[]
}

interface FolderTreeNodeProps
  extends Omit<TreeProps, 'setFiles' | 'fileStructure'> {
  checkFiles: (ids: string[], checked: boolean) => void
  folder: EPlusFolder | undefined
  fileStructure: FileStructure | null
}

type CheckboxProps = {
  element: JSX.Element
  header: string
  open: boolean
  setOpen: Dispatch<React.SetStateAction<boolean>>
  setFolderCheck: () => void
  folderCheck: boolean
}

// const getFile = (id: string, files: TestFileCheck[]) =>
//   files.find((file) => file.id === id)

// const getFolder = (id: string, folders: EPlusFolder[]) =>
//   folders.find((folder) => folder.fileId === id)

const DisclosureCheckbox = ({
  element,
  header,
  open,
  setOpen,
  folderCheck,
  setFolderCheck,
}: CheckboxProps) => {
  return (
    <Disclosure defaultOpen={open}>
      <>
        <div className="flex  w-fit px-2">
          <div
            className="flex items-center gap-2"
            onChange={(e) => e.stopPropagation()}
          >
            <input
              type="checkbox"
              name="select-all"
              className="mr-2 accent-[#107C41] border-[#616161] w-4 h-4"
              checked={folderCheck}
              onChange={setFolderCheck}
            />
          </div>
          <Disclosure.Button
            onClick={() => setOpen(!open)}
            className="flex w-full flex-grow justify-between gap-1 rounded-b-none py-1 text-left text-sm font-medium text-black "
          >
            <MdOutlineKeyboardArrowDown
              className={`${open ? 'rotate-180 transform' : ''} h-5 w-5`}
            />
            <FolderOutlinedIcon sx={{ fontSize: 20 }} />
            <label htmlFor="select-all">{header}</label>
          </Disclosure.Button>
        </div>
        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          as={'div'}
        >
          {open && (
            <Disclosure.Panel className="mt-2 px-4 pb-2 text-sm text-gray-500 bg-white rounded-b-lg ">
              {element}
            </Disclosure.Panel>
          )}
        </Transition>
      </>
    </Disclosure>
  )
}

const filterFileAndFolder = (
  fileStructure: FileStructure | null,
  files: TestFileCheck[],
  folders: EPlusFolder[]
) => {
  // console.log('fileStructure:', fileStructure)
  if (!fileStructure) {
    return {
      files: [],
      folders: [],
    }
  }
  const fileIdSet = new Set<string>()
  const folderIdSet = new Set<string>()

  const helper = (subStructure: FileStructure | null) => {
    if (!subStructure) return

    const keys = Object.keys(subStructure)

    for (const k of keys) {
      if (subStructure[k] === null) {
        fileIdSet.add(k)
      } else {
        folderIdSet.add(k)
        helper(subStructure[k])
      }
    }
  }

  helper(fileStructure)
  return {
    files: files.filter((f) => fileIdSet.has(f.id)),
    folders: folders.filter((folder) => folderIdSet.has(folder.fileId)),
  }
}

export const FileTreeNode = ({ file, checkFiles }: FileTreeNodeProps) => {
  if (!file) return <></>

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center p-2">
        <input
          type="checkbox"
          name="select-all"
          className="mr-2 accent-[#107C41] border-[#616161] w-4 h-4"
          checked={file.checked}
          onChange={() => checkFiles([file.id], !file.checked)}
        />
        <label htmlFor="select-all">{file.filename}</label>
      </div>
    </div>
  )
}

export const FolderTreeNode = ({
  fileStructure,
  files,
  folders,
  checkFiles,
  folder,
  selectedFolders,
  setSelectedFolders,
  searchMode,
}: FolderTreeNodeProps) => {
  const keys = Object.keys(fileStructure ?? {})
  const [open, setOpen] = useState(false)
  const [folderCheck, setFolderCheck] = useState(false)

  const checkFolder = () => {
    if (folderCheck) {
      checkFiles(
        files.map((file) => file.id),
        false
      )
      setFolderCheck(false)
      if (folder && selectedFolders.includes(folder.fileId)) {
        setSelectedFolders(selectedFolders.filter((f) => f !== folder.fileId))
      }
    } else {
      checkFiles(
        files.map((file) => file.id),
        true
      )
      setFolderCheck(true)
      if (folder && !selectedFolders.includes(folder.fileId)) {
        setSelectedFolders([...selectedFolders, folder.fileId])
      }
    }
  }

  useEffect(() => {
    if (files.every((f) => f.checked) && !folderCheck) {
      setFolderCheck(true)
    } else if (files.some((f) => !f.checked) && folderCheck) {
      setFolderCheck(false)
    }
  }, [files, folderCheck])

  return folder && fileStructure ? (
    <DisclosureCheckbox
      header={folder.folderName}
      folderCheck={folderCheck}
      setFolderCheck={checkFolder}
      open={open}
      setOpen={setOpen}
      element={
        <div className="flex flex-col gap-2">
          {keys.map((k) => {
            if (fileStructure[k] == null) {
              const file = files.find((f) => f.id === k)
              if (searchMode === 1 && file)
                return (
                  <FileTreeNode
                    checkFiles={checkFiles}
                    file={file}
                    key={k}
                    selectedFolders={selectedFolders}
                    setSelectedFolders={setSelectedFolders}
                  />
                )
              return <div key={k} className="hidden"></div>
            }
            const { files: fs, folders: fos } = filterFileAndFolder(
              fileStructure[k],
              files,
              folders
            )
            const folder = folders.find((f) => f.fileId === k)
            return (
              <FolderTreeNode
                fileStructure={fileStructure[k]}
                files={fs}
                folders={fos}
                checkFiles={checkFiles}
                key={k}
                folder={folder}
                selectedFolders={selectedFolders}
                setSelectedFolders={setSelectedFolders}
                searchMode={searchMode}
              />
            )
          })}
        </div>
      }
    />
  ) : (
    <></>
  )
}

const FilesTree = ({
  fileStructure,
  files,
  folders,
  setFiles,
  selectedFolders,
  setSelectedFolders,
  searchMode,
}: TreeProps) => {
  const keys = Object.keys(fileStructure)
  const checkFiles = (ids: string[], checked: boolean) => {
    const idSet = new Set(ids)
    setFiles((files) =>
      files.map((f) => {
        if (idSet.has(f.id)) {
          return { ...f, checked }
        }
        return f
      })
    )
  }

  return (
    <div className="flex flex-col gap-2">
      {keys.map((k) => {
        if (fileStructure[k] == null) {
          const file = files.find((f) => f.id === k)
          if (searchMode === 1 && file)
            return (
              <FileTreeNode
                checkFiles={checkFiles}
                file={file}
                key={k}
                selectedFolders={selectedFolders}
                setSelectedFolders={setSelectedFolders}
              />
            )
          // return <FileTreeNode checkFiles={checkFiles} file={file} key={k} />
          return <div className="hidden" key={k}></div>
        }
        const { files: fs, folders: fos } = filterFileAndFolder(
          fileStructure[k],
          files,
          folders
        )
        const folder = folders.find((f) => f.fileId === k)

        return (
          <FolderTreeNode
            key={k}
            fileStructure={fileStructure[k]}
            files={fs}
            folders={fos}
            checkFiles={checkFiles}
            folder={folder}
            selectedFolders={selectedFolders}
            setSelectedFolders={setSelectedFolders}
            searchMode={searchMode}
          />
        )
      })}
    </div>
  )
}

export default FilesTree
