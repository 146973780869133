import { ErrorBoundary } from "react-error-boundary"
import DataMatching from "../components/DataMatching"
import CustomErrorBoundary from "../components/CustomErrorBoundary"

const DataMatchPage = () => {
    return <ErrorBoundary
        FallbackComponent={({ error, resetErrorBoundary }) => <CustomErrorBoundary
            error={error}
            resetErrorBoundary={resetErrorBoundary} />}>
        <DataMatching />
    </ErrorBoundary>
}

export default DataMatchPage