import { flexRender, Table } from '@tanstack/react-table'
import { DataMatchSelection } from '../../types'
import { Dispatch } from 'react'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'

type OutputElemProps = {
  table: Table<DataMatchSelection>
  setOutputTableError: Dispatch<React.SetStateAction<boolean>>
  outputTableError: boolean
  getInitialOutputCols: (noOutput?: boolean) => Promise<void>
  setStepper: Dispatch<React.SetStateAction<0 | 1 | 2 | -1>>
  onMatchClick: () => void
}

const OutputElem = ({
  table,
  outputTableError,
  setOutputTableError,
  getInitialOutputCols,
  onMatchClick,
  setStepper,
}: OutputElemProps) => (
  <>
    <div className="bg-white px-3 py-2 rounded">
      <div className="flex w-full justify-center">
        <table className="border w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="border-b-2">
                {headerGroup.headers.map((header) => (
                  <th key={header.id} className="p-2 border-r-2">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id} className="border-b-2 hover:bg-gray-100">
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id} className="border-r-2 p-2 ">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      {outputTableError && (
        <div className="flex w-full animate-pulse">
          <div
            className="p-2 bg-red-100 items-center text-red-700 leading-none lg:rounded-full flex lg:inline-flex w-full"
            role="alert"
          >
            <span className="flex rounded-full bg-red-300 uppercase px-2 py-1 text-xs font-bold mr-3">
              Error
            </span>
            <span className="font-semibold mr-2 text-left flex-auto">
              Duplicated output
            </span>
            <span>
              <button
                className="hover:bg-red-200 rounded transition  hover:scale-105"
                onClick={() => setOutputTableError(false)}
              >
                <CloseOutlinedIcon />
              </button>
            </span>
          </div>
        </div>
      )}

      <div className="flex justify-between">
        <div className="mt-4 ">
          <button
            type="button"
            className="inline-flex m-1  hover:text-white border border-yellow-400 hover:bg-yellow-500  font-medium rounded-2xl text-xs sm:text-sm px-2 py-1 sm:px-4 sm:py-2 text-center"
            onClick={() =>
              getInitialOutputCols(true).then(() => setOutputTableError(false))
            }
          >
            {'Clear Outputs'}
          </button>
        </div>
        <div className="mt-4 flex flex-row-reverse justify-start h-fit">
          <button
            disabled={outputTableError}
            type="button"
            className="min-w-16 inline-flex text-white m-1 justify-center rounded-full px-2 py-1 sm:px-4 sm:py-2 text-xs sm:text-sm font-medium bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br"
            onClick={onMatchClick}
          >
            {'Match files'}
          </button>
          <button
            type="button"
            className="min-w-16  inline-flex m-1 text-teal-600 justify-center rounded-full items-center border border-teal-500 px-2 py-1 sm:px-4 sm:py-2 text-xs sm:text-sm font-medium hover:bg-gray-100"
            onClick={() => setStepper(1)}
          >
            {'Prev'}
          </button>
        </div>
      </div>
    </div>
  </>
)

export default OutputElem
